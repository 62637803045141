// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Input from "./Input.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Styles from "./styles.mjs";
import Shortid from "shortid";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconTrees from "./IconTrees.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as NamedBranch from "./NamedBranch.mjs";
import * as Collaborators from "./Collaborators.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import UrlSafeString from "url-safe-string";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";

var safeBranchName = new UrlSafeString({
      trimWhitespace: false
    });

function createBranch(branchName, branchOrigin, sendActions, schemaBundle, onError, onSuccess, param) {
  var branchId = Shortid();
  var user = Belt_Option.getExn(Caml_option.nullable_to_opt(Firebase.app(undefined).auth().currentUser));
  var userId = user.uid;
  var email = user.email;
  var email$1 = (email == null) ? undefined : Caml_option.some(email);
  Curry.app(sendActions, [
        {
          NAME: "Branch",
          VAL: branchId
        },
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        (function (branch) {
            Curry._1(onSuccess, branchId);
            var schemaBundle$1 = AnalyticsUtils.enrichSchemaBundleWithNamedBranch(schemaBundle, branch);
            AnalyticsRe.branchCreated(schemaBundle$1.schemaId, schemaBundle$1.schemaName, schemaBundle$1.schemaBillingStatus, schemaBundle$1.branchId, schemaBundle$1.branchName, schemaBundle$1.schemaSubscriptionPlan, schemaBundle$1.schemaTrialPlan, schemaBundle$1.authenticationConfig, schemaBundle$1.schemaSubscriptionPlanSlug, schemaBundle$1.schemaTrialPlanSlug, schemaBundle$1.schemaType, branchId, branchName, branchOrigin, schemaBundle$1.branchId, schemaBundle$1.schemaId);
            return Collaborators.addCollaborator(schemaBundle$1, userId, NamedBranch.toUnnamed(branch), "Branch", branchId, email$1, "NewBranch", sendActions, undefined, userId);
          }),
        onError,
        [[
            {
              NAME: "BranchInit",
              VAL: [
                branchId,
                branchName
              ]
            },
            {
              branchId: branchId,
              branchQuery: branchId
            }
          ]]
      ]);
  return Curry.app(sendActions, [
              "Master",
              false,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              [[
                  {
                    NAME: "OpenBranch",
                    VAL: [
                      branchId,
                      branchName
                    ]
                  },
                  {
                    branchId: branchId,
                    branchQuery: branchId
                  }
                ]]
            ]);
}

function NewBranchModal(Props) {
  var openBranches = Props.openBranches;
  var onCreateOpt = Props.onCreate;
  var intialName = Props.intialName;
  var onCreate = onCreateOpt !== undefined ? onCreateOpt : (function (param, param$1) {
        
      });
  var schemaBundle = SchemaBundleContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var match = React.useState(function () {
        return safeBranchName.generate(intialName);
      });
  var setName = match[1];
  var name = match[0];
  var match$1 = React.useState(function () {
        return /* Idle */0;
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var branchNameExists = Belt_List.has(openBranches, name, (function (param, name) {
          return param[1] === name;
        })) || name === "master" || name === "main";
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("relative"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: Css.padding2(Css.px(15), Css.px(15)),
                        tl: {
                          hd: Css.maxWidth(Css.pct(90)),
                          tl: {
                            hd: Css.width(Css.px(250)),
                            tl: {
                              hd: Css.maxHeight(Css.pct(100)),
                              tl: {
                                hd: Css.boxShadow(Styles.Shadow.box),
                                tl: {
                                  hd: Css.borderRadius(Styles.Border.radius),
                                  tl: {
                                    hd: Css.cursor("default"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.alignItems("center"),
                          tl: {
                            hd: Css.justifyContent("center"),
                            tl: {
                              hd: Css.marginTop(Css.px(-110)),
                              tl: {
                                hd: Css.width(Css.pct(100)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      })
                }, React.createElement(IconTrees.make, {
                      size: 144
                    })), React.createElement("form", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(15)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.alignItems("stretch"),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      if (!branchNameExists && status !== /* Loading */1) {
                        Curry._1(setStatus, (function (param) {
                                return /* Loading */1;
                              }));
                        return createBranch(name, "NewBranchModal", sendActions, schemaBundle, undefined, (function (branchId) {
                                      Curry._1(globalSend, {
                                            TAG: /* CloseModal */5,
                                            _0: undefined
                                          });
                                      Router.Schema.pushRoute(undefined, {
                                            NAME: "branch",
                                            VAL: branchId
                                          }, undefined, undefined, undefined, undefined, undefined);
                                      return Curry._2(onCreate, branchId, name);
                                    }), undefined);
                      }
                      
                    })
                }, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.marginBottom(Css.px(10)),
                            tl: /* [] */0
                          })
                    }, React.createElement(Input.make, {
                          label: "Branch Name",
                          value: name,
                          onChange: (function (newName) {
                              return Curry._1(setName, (function (param) {
                                            return safeBranchName.generate(newName);
                                          }));
                            }),
                          autoFocus: true,
                          placeholder: "update-signup-event",
                          className: {
                            hd: Css.fontWeight(Styles.FontWeight.semi),
                            tl: /* [] */0
                          },
                          required: true
                        })), branchNameExists && status !== /* Loading */1 ? React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.fontWeight(Styles.FontWeight.semi),
                              tl: {
                                hd: Css.color(Styles.Color.orange),
                                tl: {
                                  hd: Css.fontSize(Styles.FontSize.regular),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, "Branch name already exists.") : null, React.createElement(Button.make, {
                      disabled: branchNameExists || status === /* Loading */1,
                      label: "Create",
                      loading: status === /* Loading */1,
                      size: "large"
                    })));
}

var make = NewBranchModal;

export {
  safeBranchName ,
  createBranch ,
  make ,
  
}
/* safeBranchName Not a pure module */
