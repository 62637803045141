// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Sentry from "./externals/Sentry.mjs";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.mjs";
import LodashDebounce from "lodash.debounce";
import * as DiscrepancyUtils from "./DiscrepancyUtils.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";
import * as TrackingPlanDiscrepanciesWorkerInstance from "./workers/TrackingPlanDiscrepanciesWorkerInstance.mjs";

var context = React.createContext(DiscrepancyUtils.emptyDiscrepanciesData);

function DiscrepancyContext$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  var provider = context.Provider;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  make: DiscrepancyContext$Provider
};

var debounceDiscrepencyCheck = LodashDebounce((function (fn, param) {
        return Curry._3(fn, param[0], param[1], param[2]);
      }), 5000, {
      leading: false,
      trailing: true
    });

function checkDiscrepancies(eventComponentsWithNames, config, model, setState) {
  $$Promise.$$catch(TrackingPlanDiscrepanciesWorkerInstance.getDiscrepancies(eventComponentsWithNames, config, model).then(function (discrepancies) {
            return Curry._1(setState, (function (param) {
                          return discrepancies;
                        }));
          }), (function (e) {
          console.error("Failed to resolve tracking plan discrepancies from Worker", e);
          Sentry.captureException(e, {
                message: "Failed to resolve tracking plan discrepancies from Worker",
                config: ValidationConfigV2.encode(config)
              });
          Curry._1(setState, (function (param) {
                  return DiscrepancyUtils.getDiscrepancies(eventComponentsWithNames, config, model);
                }));
          return Promise.resolve(undefined);
        }));
  
}

function DiscrepancyContext(Props) {
  var config = Props.config;
  var configV2 = Props.configV2;
  var model = Props.model;
  var forceAuditV1 = Props.forceAuditV1;
  var children = Props.children;
  var workspace = WorkspaceContext.use(undefined);
  var match = React.useState(function () {
        return DiscrepancyUtils.emptyDiscrepanciesData;
      });
  var setValue = match[1];
  var parsedConfig = React.useMemo((function () {
          return ValidationConfigV2.Utils.parseWithV1(config, configV2, forceAuditV1);
        }), [
        config,
        configV2,
        forceAuditV1
      ]);
  var eventComponentsWithNames = EventNameComponentCollection.useEventNameComponents(workspace.id, ValidationConfigV2.EventsV2.getNameConfigAsString(parsedConfig.events));
  React.useEffect((function () {
          var timeoutId = setTimeout((function (param) {
                  return checkDiscrepancies(eventComponentsWithNames, parsedConfig, model, setValue);
                }), 0);
          return (function (param) {
                    clearTimeout(timeoutId);
                    
                  });
        }), []);
  React.useEffect((function () {
          debounceDiscrepencyCheck((function (param, param$1, param$2) {
                  return checkDiscrepancies(eventComponentsWithNames, param, param$1, param$2);
                }), [
                parsedConfig,
                model,
                setValue
              ]);
          
        }), [
        eventComponentsWithNames,
        model,
        parsedConfig
      ]);
  React.useEffect((function () {
          checkDiscrepancies(eventComponentsWithNames, parsedConfig, model, setValue);
          
        }), [
        eventComponentsWithNames,
        model,
        config,
        configV2
      ]);
  return React.createElement(DiscrepancyContext$Provider, {
              value: match[0],
              children: children
            });
}

var make = DiscrepancyContext;

export {
  context ,
  Provider ,
  debounceDiscrepencyCheck ,
  checkDiscrepancies ,
  make ,
  
}
/* context Not a pure module */
