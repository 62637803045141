// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Printer from "./Printer.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";

var _map = {"SecurityAuditLog":"SecurityAuditLog","ActivityLogs":"ActivityLogs","TrackingPlanModelChange":"TrackingPlanModelChange"};

function tToJs(param) {
  return param;
}

function tFromJs(param) {
  return _map[param];
}

var ActionImpactLevel = {
  tToJs: tToJs,
  tFromJs: tFromJs
};

function getActionImpactLevelFromAction(action) {
  if (typeof action !== "object") {
    if (action === "StartedImport") {
      return "ActivityLogs";
    } else if (action === "SchemaAccessed") {
      return "SecurityAuditLog";
    } else {
      return "TrackingPlanModelChange";
    }
  }
  var variant = action.NAME;
  if (variant === "UpdateTrackingPlanAuditConfig" || variant === "RemoveDomainMembers" || variant === "UpdateIntegrationDestinationIds" || variant === "CollaboratorRemoved" || variant === "CreateServiceAccount" || variant === "AutoPublishIntegration" || variant === "ArchiveSavedView" || variant === "CodeGenerated" || variant === "UpdateDomainDescription" || variant === "CreateInspectorIssue" || variant === "PropertyComment" || variant === "DeleteComment" || variant === "CreatePublicBranchImplementation" || variant === "ArchiveDomain" || variant === "UpdateDomainName" || variant === "CreateDomain" || variant === "AddDomainMembers" || variant === "PublishIntegration" || variant === "UpdateDomainMemberRole" || variant === "GenerateWebhookSecret" || variant === "ReviewerAdded" || variant === "GoalComment" || variant === "UpdateSavedView" || variant === "IntegrationComment" || variant === "InspectorIssueComment" || variant === "RemoveDomainItems" || variant === "DeleteServiceAccount" || variant === "MetricComment" || variant === "DeletePublicBranchImplementation" || variant === "CollaboratorAdded" || variant === "PropertyGroupComment" || variant === "UpdateInspectorIssueStatus" || variant === "EventComment" || variant === "BranchComment" || variant === "AddDomainItems" || variant === "ReviewerRemoved" || variant === "PaymentCompleted" || variant === "SetBranchStatus" || variant === "CreateSavedView") {
    return "ActivityLogs";
  } else if (variant === "RemoveMember" || variant === "ResendInvite" || variant === "SubscriptionStarted" || variant === "SubscriptionCancelled" || variant === "SubscriptionUpdated" || variant === "UpdateMemberRole" || variant === "RemoveInvite" || variant === "InviteMember") {
    return "SecurityAuditLog";
  } else {
    return "TrackingPlanModelChange";
  }
}

function splitActionsIntoByteSizedChunks(maxChunkByteSizeOpt, actions) {
  var maxChunkByteSize = maxChunkByteSizeOpt !== undefined ? maxChunkByteSizeOpt : 1000000;
  var actionsWithSize = Belt_List.map(actions, (function (action) {
          var actionSize = JSON.stringify(Printer.printAction(action)).length;
          return [
                  action,
                  actionSize
                ];
        }));
  return Belt_List.reduceWithIndex(actionsWithSize, [
                /* [] */0,
                [
                  /* [] */0,
                  0
                ]
              ], (function (param, param$1, index) {
                  var actionSize = param$1[1];
                  var action = param$1[0];
                  var match = param[1];
                  var currentChunkSize = match[1];
                  var currentChunk = match[0];
                  var chunks = param[0];
                  var match$1 = (currentChunkSize + actionSize | 0) < maxChunkByteSize || Belt_List.length(currentChunk) === 0 ? [
                      chunks,
                      [
                        Belt_List.concat(currentChunk, {
                              hd: action,
                              tl: /* [] */0
                            }),
                        currentChunkSize + actionSize | 0
                      ]
                    ] : [
                      Belt_List.concat(chunks, {
                            hd: currentChunk,
                            tl: /* [] */0
                          }),
                      [
                        {
                          hd: action,
                          tl: /* [] */0
                        },
                        actionSize
                      ]
                    ];
                  var match$2 = match$1[1];
                  var currentChunk$1 = match$2[0];
                  var chunks$1 = match$1[0];
                  if (index === (Belt_List.length(actionsWithSize) - 1 | 0) && Belt_List.length(currentChunk$1) > 0) {
                    return [
                            Belt_List.concat(chunks$1, {
                                  hd: currentChunk$1,
                                  tl: /* [] */0
                                }),
                            [
                              /* [] */0,
                              0
                            ]
                          ];
                  } else {
                    return [
                            chunks$1,
                            [
                              currentChunk$1,
                              match$2[1]
                            ]
                          ];
                  }
                }))[0];
}

export {
  ActionImpactLevel ,
  getActionImpactLevelFromAction ,
  splitActionsIntoByteSizedChunks ,
  
}
/* Printer Not a pure module */
