// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../../bs-case/src/case.mjs";
import * as Icon from "../Icon.mjs";
import * as $$Text from "../Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../Button.mjs";
import * as Select from "../Select.mjs";
import * as Spacer from "../Spacer.mjs";
import * as Styles from "../styles.mjs";
import * as Switch from "../Switch.mjs";
import * as Tooltip from "../Tooltip.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Markdown from "../Markdown.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "../TextButton.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "../KeyListener.mjs";
import * as EntityLightning from "../EntityLightning.mjs";
import * as GlobalSendContext from "../GlobalSendContext.mjs";
import * as ValidationConfigV2 from "./ValidationConfigV2.mjs";
import * as GuardRailsConfigUtils from "./GuardRailsConfigUtils.mjs";

var row = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.height(Css.px(20)),
          tl: /* [] */0
        }
      }
    });

var Style = {
  row: row
};

function GuardRailsConfigComponents$GuardRailContainer(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, children);
}

var GuardRailContainer = {
  make: GuardRailsConfigComponents$GuardRailContainer
};

function GuardRailsConfigComponents$GuardRailSectionTitle(Props) {
  var title = Props.title;
  return React.createElement("div", undefined, React.createElement($$Text.make, {
                  size: "Medium",
                  weight: "Semi",
                  color: Styles.Color.light12,
                  children: title
                }));
}

var GuardRailSectionTitle = {
  make: GuardRailsConfigComponents$GuardRailSectionTitle
};

function GuardRailsConfigComponents$GuardRailSection(Props) {
  var title = Props.title;
  var children = Props.children;
  var rightSectionOpt = Props.rightSection;
  var rightSection = rightSectionOpt !== undefined ? Caml_option.valFromOption(rightSectionOpt) : null;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: {
                          hd: Css.marginBottom(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("flex"),
                        tl: {
                          hd: Css.flexDirection("row"),
                          tl: {
                            hd: Css.justifyContent("spaceBetween"),
                            tl: {
                              hd: Css.maxHeight(Css.px(20)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(GuardRailsConfigComponents$GuardRailSectionTitle, {
                      title: title
                    }), rightSection), children);
}

var GuardRailSection = {
  make: GuardRailsConfigComponents$GuardRailSection
};

function GuardRailsConfigComponents$GuardRailSubSection(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(16)),
                        tl: {
                          hd: Css.paddingLeft(Css.px(16)),
                          tl: /* [] */0
                        }
                      }
                    }
                  })
            }, children);
}

var GuardRailSubSection = {
  make: GuardRailsConfigComponents$GuardRailSubSection
};

function GuardRailsConfigComponents$GuardRailRow(Props) {
  var name = Props.name;
  var enforceAvailability = Props.enforceAvailability;
  var enforcePlanHint = Props.enforcePlanHint;
  var viewerCanEdit = Props.viewerCanEdit;
  var rule = Props.rule;
  var configUpgradePrompt = Props.configUpgradePrompt;
  var commonNameCase = Props.commonNameCase;
  var handleChange = Props.handleChange;
  var tooltipMarkdown = Props.tooltipMarkdown;
  var withTogglesOpt = Props.withToggles;
  var nameType = Props.nameType;
  var withToggles = withTogglesOpt !== undefined ? withTogglesOpt : true;
  var globalSend = GlobalSendContext.use(undefined);
  var match = rule.value;
  var tmp;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (variant === "nameComponents" || variant === "blockedValues" || variant === "allowedValues") {
      tmp = null;
    } else {
      var casingOptions = nameType === "advancedCase" ? Belt_Array.keep(Case.casingOptions, (function ($$case) {
                return $$case !== "None";
              })) : Case.casingOptions;
      tmp = React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                width: 2
              }), React.createElement(Select.make, {
                disabled: !viewerCanEdit,
                onSelect: (function (selected) {
                    return Curry._1(handleChange, {
                                value: {
                                  NAME: "case",
                                  VAL: Belt_Option.getWithDefault(Case.tFromJs(selected), "None")
                                },
                                severity: rule.severity
                              });
                  }),
                options: Belt_Array.map(casingOptions, (function ($$case) {
                        return GuardRailsConfigUtils.getOptionForCasing($$case, nameType, commonNameCase);
                      })),
                value: Case.tToJs(match.VAL)
              }));
    }
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              className: row
            }, React.createElement($$Text.make, {
                  size: "Medium",
                  color: Styles.Color.light12,
                  children: name
                }), React.createElement(Spacer.make, {
                  width: 2
                }), React.createElement(Tooltip.Default.make, {
                  size: "small",
                  title: name,
                  children: React.createElement(Tooltip.Content.make, {
                        children: React.createElement(Markdown.make, {
                              source: tooltipMarkdown
                            })
                      })
                }), React.createElement(React.Fragment, undefined, tmp), withToggles ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        width: 32,
                        grow: 1.0
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Switch.make, {
                            value: Curry._1(ValidationConfigV2.Rule.isChecked, rule),
                            onChange: (function (_toggled) {
                                return Curry._1(handleChange, ValidationConfigV2.Utils.toggleIsSet(rule));
                              }),
                            disabled: !viewerCanEdit || Curry._1(ValidationConfigV2.Rule.isEnforced, rule) && enforceAvailability === "Available"
                          })), React.createElement(Spacer.make, {
                        width: 16
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: Css.position("relative"),
                              tl: /* [] */0
                            })
                      }, React.createElement(Switch.make, {
                            value: Curry._1(ValidationConfigV2.Rule.isEnforced, rule),
                            onChange: (function (toggled) {
                                if (enforceAvailability === "Unavailable") {
                                  return Curry._1(globalSend, configUpgradePrompt);
                                } else {
                                  return Curry._1(handleChange, ValidationConfigV2.Utils.toggleSeverity(rule, toggled));
                                }
                              }),
                            faded: enforceAvailability === "Unavailable",
                            disabled: !viewerCanEdit || !Curry._1(ValidationConfigV2.Rule.isChecked, rule)
                          }), React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.top(Css.px(2)),
                                    tl: {
                                      hd: Css.right(Css.px(-16)),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement(EntityLightning.make, {
                                availability: enforceAvailability,
                                planHint: enforcePlanHint,
                                fill: Styles.Color.light08
                              })))) : null);
}

var GuardRailRow = {
  make: GuardRailsConfigComponents$GuardRailRow
};

function GuardRailsConfigComponents$NameComponentDetails(Props) {
  var nameComponents = Props.nameComponents;
  return Belt_Array.keepMap(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return ;
                }
                var buildingBlock = nameComponent.VAL;
                var $$case = Curry._1(ValidationConfigV2.Value.getCase, buildingBlock.case.value);
                return Caml_option.some(React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                                    size: "Small",
                                    children: React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.width("fitContent"),
                                                tl: {
                                                  hd: Css.fontFamily(Styles.FontFamily.polar),
                                                  tl: {
                                                    hd: Css.fontWeight(Styles.FontWeight.semi),
                                                    tl: {
                                                      hd: Css.borderRadius(Css.px(4)),
                                                      tl: {
                                                        hd: Css.backgroundColor(Styles.Color.white),
                                                        tl: {
                                                          hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                                          tl: {
                                                            hd: Css.padding2(Css.px(4), Css.px(8)),
                                                            tl: /* [] */0
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                        }, React.createElement($$Text.make, {
                                              element: "Span",
                                              color: Styles.Color.light10,
                                              children: "<"
                                            }), Case.to_($$case, buildingBlock.name), React.createElement($$Text.make, {
                                              element: "Span",
                                              color: Styles.Color.light10,
                                              children: ">"
                                            }))
                                  }), React.createElement("div", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.paddingLeft(Css.px(9)),
                                          tl: /* [] */0
                                        })
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.display("flex"),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, React.createElement(Icon.make, {
                                            type_: "arrowDownRightTurn",
                                            size: "small",
                                            color: Styles.Color.light11
                                          }), React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            color: Styles.Color.light10,
                                            children: "Case should be"
                                          }), React.createElement(Spacer.make, {
                                            width: 4
                                          }), React.createElement($$Text.make, {
                                            element: "Span",
                                            size: "Small",
                                            weight: "Semi",
                                            color: Styles.Color.light12,
                                            children: React.createElement("span", {
                                                  className: Curry._1(Css.style, {
                                                        hd: Css.fontFamily(Styles.FontFamily.polar),
                                                        tl: /* [] */0
                                                      })
                                                }, Case.toUILabel($$case))
                                          })), Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock).length !== 0 ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(Icon.make, {
                                              type_: "arrowDownRightTurn",
                                              size: "small",
                                              color: Styles.Color.light11
                                            }), React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: "Blocked words: "
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              weight: "Semi",
                                              color: Styles.Color.light12,
                                              children: React.createElement("span", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontFamily(Styles.FontFamily.polar),
                                                          tl: /* [] */0
                                                        })
                                                  }, Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getBlockedValues, buildingBlock).join(", "))
                                            })) : null, Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock).length !== 0 ? React.createElement("div", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: /* [] */0
                                                }
                                              })
                                        }, React.createElement(Icon.make, {
                                              type_: "arrowDownRightTurn",
                                              size: "small",
                                              color: Styles.Color.light11
                                            }), React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: "Allowed values: "
                                            }), React.createElement(Spacer.make, {
                                              width: 4
                                            }), React.createElement($$Text.make, {
                                              element: "Span",
                                              size: "Small",
                                              weight: "Semi",
                                              color: Styles.Color.light12,
                                              children: React.createElement("span", {
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.fontFamily(Styles.FontFamily.polar),
                                                          tl: /* [] */0
                                                        })
                                                  }, Curry._1(ValidationConfigV2.NameComponent.NameBuildingBlock.getAllowedValues, buildingBlock).join(", "))
                                            })) : null)));
              }));
}

var NameComponentDetails = {
  make: GuardRailsConfigComponents$NameComponentDetails
};

function GuardRailsConfigComponents$AdvancedNameConventions(Props) {
  var nameComponents = Props.nameComponents;
  var onAdvancedClick = Props.onAdvancedClick;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.flexDirection("column"),
                      tl: {
                        hd: Css.gap(Css.px(8)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement($$Text.make, {
                  element: "Span",
                  size: "Small",
                  color: Styles.Color.light10,
                  children: null
                }, "Event name structure is ", React.createElement("span", {
                      className: Curry._1(Css.style, {
                            hd: Css.borderRadius(Css.px(4)),
                            tl: {
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.border(Css.px(1), "solid", Styles.Color.light04),
                                tl: {
                                  hd: Css.padding2(Css.px(4), Css.px(8)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          })
                    }, React.createElement($$Text.make, {
                          element: "Span",
                          weight: "Semi",
                          color: Styles.Color.light12,
                          children: React.createElement("span", {
                                className: Curry._1(Css.style, {
                                      hd: Css.fontFamily(Styles.FontFamily.polar),
                                      tl: {
                                        hd: Css.unsafe("whiteSpace", "preserve"),
                                        tl: /* [] */0
                                      }
                                    })
                              }, Belt_Array.mapWithIndex(nameComponents, (function (index, nameComponent) {
                                      if (nameComponent.NAME === "separator") {
                                        var separator = nameComponent.VAL;
                                        return React.createElement("span", {
                                                    key: separator + String(index),
                                                    className: Curry._1(Css.style, {
                                                          hd: Css.letterSpacing(Css.px(1)),
                                                          tl: {
                                                            hd: Css.unsafe("whiteSpace", "preserve"),
                                                            tl: /* [] */0
                                                          }
                                                        })
                                                  }, separator);
                                      }
                                      var match = nameComponent.VAL;
                                      var name = match.name;
                                      var $$case = Curry._1(ValidationConfigV2.Value.getCase, match.case.value);
                                      return React.createElement(React.Fragment, {
                                                  children: null,
                                                  key: name + String(index)
                                                }, React.createElement($$Text.make, {
                                                      element: "Span",
                                                      color: Styles.Color.light10,
                                                      children: "<"
                                                    }), Case.to_($$case, name), React.createElement($$Text.make, {
                                                      element: "Span",
                                                      color: Styles.Color.light10,
                                                      children: ">"
                                                    }));
                                    })))
                        }))), React.createElement(GuardRailsConfigComponents$NameComponentDetails, {
                  nameComponents: nameComponents
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(320)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.gap(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(TextButton.make, {
                      onClick: onAdvancedClick,
                      style: "Purple",
                      children: "Set advanced rules"
                    }), React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      color: Styles.Color.light10,
                      children: "Advanced name conventions have been set for this workspace. To edit them use the advanced section."
                    })));
}

var AdvancedNameConventions = {
  make: GuardRailsConfigComponents$AdvancedNameConventions
};

function GuardRailsConfigComponents$BasicNameConventions(Props) {
  var canForceBranchAudit = Props.canForceBranchAudit;
  var commonEventNameCase = Props.commonEventNameCase;
  var config = Props.config;
  var configAvailability = Props.configAvailability;
  var configPlanHint = Props.configPlanHint;
  var forceBranchAuditPlanHint = Props.forceBranchAuditPlanHint;
  var handleRuleChange = Props.handleRuleChange;
  var rule = Props.rule;
  var viewerCanEdit = Props.viewerCanEdit;
  var onAdvancedClick = Props.onAdvancedClick;
  return React.createElement(React.Fragment, undefined, React.createElement(GuardRailsConfigComponents$GuardRailRow, {
                  name: "Case should be",
                  editAvailability: configAvailability,
                  editPlanHint: configPlanHint,
                  enforceAvailability: canForceBranchAudit,
                  enforcePlanHint: forceBranchAuditPlanHint,
                  viewerCanEdit: viewerCanEdit,
                  rule: rule,
                  configUpgradePrompt: {
                    TAG: /* OpenModal */4,
                    _0: {
                      NAME: "BillingPrompt",
                      VAL: "IssueReportConfig"
                    }
                  },
                  commonNameCase: commonEventNameCase,
                  handleChange: (function (updatedRule) {
                      var init = config.events;
                      var init$1 = Curry._1(ValidationConfigV2.Rule.empty, "none");
                      return Curry._3(handleRuleChange, "ConsistentEventNameCasing", updatedRule, {
                                  events: {
                                    nameComponents: {
                                      value: {
                                        NAME: "nameComponents",
                                        VAL: [{
                                            NAME: "nameBuildingBlock",
                                            VAL: {
                                              name: "",
                                              case: updatedRule,
                                              allowedValues: {
                                                value: {
                                                  NAME: "allowedValues",
                                                  VAL: []
                                                },
                                                severity: "none"
                                              },
                                              blockedValues: {
                                                value: {
                                                  NAME: "blockedValues",
                                                  VAL: []
                                                },
                                                severity: "none"
                                              }
                                            }
                                          }]
                                      },
                                      severity: init$1.severity
                                    },
                                    uniqueName: init.uniqueName,
                                    description: init.description
                                  },
                                  properties: config.properties,
                                  pointOfBlock: config.pointOfBlock
                                });
                    }),
                  tooltipMarkdown: "Checks that event name casing is consistent across all events to maximize data accessibility.",
                  nameType: "simple"
                }), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.maxWidth(Css.px(320)),
                        tl: {
                          hd: Css.display("flex"),
                          tl: {
                            hd: Css.flexDirection("column"),
                            tl: {
                              hd: Css.gap(Css.px(8)),
                              tl: /* [] */0
                            }
                          }
                        }
                      })
                }, React.createElement(TextButton.make, {
                      onClick: onAdvancedClick,
                      style: "Purple",
                      children: "Set advanced rules"
                    }), React.createElement($$Text.make, {
                      size: "Small",
                      color: Styles.Color.light10,
                      children: "Advanced rules let you define name structure, allowed values and custom casing rules"
                    })));
}

var BasicNameConventions = {
  make: GuardRailsConfigComponents$BasicNameConventions
};

function container(h, disabled, containerClassName, warning, error) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.display("block"),
                    tl: {
                      hd: Css.height(Css.px(h)),
                      tl: {
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.borderRadius(Css.px(5)),
                          tl: {
                            hd: Css.unsafe("textWrap", "nowrap"),
                            tl: {
                              hd: Css.outline(Css.px(1), "solid", error ? Styles.Color.paleRed : (
                                      warning ? Styles.Color.orange : Styles.Color.light08
                                    )),
                              tl: {
                                hd: Css.hover({
                                      hd: Css.outlineColor(error ? Styles.Color.red : (
                                              warning ? Styles.Color.darkOrange : Styles.Color.light08
                                            )),
                                      tl: /* [] */0
                                    }),
                                tl: {
                                  hd: Css.transition({
                                        NAME: "ms",
                                        VAL: 150.0
                                      }, undefined, undefined, "outline-color"),
                                  tl: {
                                    hd: Css.focusWithin({
                                          hd: Css.outline(Css.px(2), "solid", error ? Styles.Color.darkRed : (
                                                  warning ? Styles.Color.darkOrange : Styles.Color.darkBlue
                                                )),
                                          tl: /* [] */0
                                        }),
                                    tl: {
                                      hd: disabled ? Css.backgroundColor(Styles.Color.light04) : Styles.emptyStyle,
                                      tl: {
                                        hd: Css.paddingTop(Css.px(2)),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: containerClassName,
                tl: /* [] */0
              }
            });
}

var allowedValueInput = Curry._1(Css.style, {
      hd: Css.backgroundColor("transparent"),
      tl: {
        hd: Css.borderWidth(Css.px(0)),
        tl: {
          hd: Css.color(Styles.Color.light11),
          tl: {
            hd: Css.placeholder({
                  hd: Css.color(Styles.Color.light07),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

function hiddenSpan(inputHeightOffset, className) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.unsafe("whiteSpace", "preserve"),
                    tl: {
                      hd: Css.unsafe("textWrap", "nowrap"),
                      tl: {
                        hd: Css.visibility("hidden"),
                        tl: {
                          hd: Css.padding4(Css.px(4), Css.px(6), Css.px(8), Css.px(6)),
                          tl: {
                            hd: Css.borderRadius(Css.px(5)),
                            tl: {
                              hd: Css.top(Css.px(inputHeightOffset)),
                              tl: {
                                hd: Css.fontSize("unset"),
                                tl: {
                                  hd: Css.width(Css.pct(100.0)),
                                  tl: /* [] */0
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: allowedValueInput,
                tl: {
                  hd: className,
                  tl: /* [] */0
                }
              }
            });
}

function mergedInput(inputHeightOffset, className) {
  return Curry._1(Css.merge, {
              hd: Curry._1(Css.style, {
                    hd: Css.boxSizing("contentBox"),
                    tl: {
                      hd: Css.lineHeight("inherit_"),
                      tl: {
                        hd: Css.top(Css.px(inputHeightOffset + 1 | 0)),
                        tl: {
                          hd: Css.fontSize("inherit_"),
                          tl: {
                            hd: Css.textAlign("inherit_"),
                            tl: {
                              hd: Css.display("inline"),
                              tl: {
                                hd: Css.unsafe("appearance", "none"),
                                tl: {
                                  hd: Css.unsafe("outline", "none"),
                                  tl: {
                                    hd: Css.unsafe("border", "none"),
                                    tl: {
                                      hd: Css.height("auto"),
                                      tl: {
                                        hd: Css.margin("zero"),
                                        tl: {
                                          hd: Css.padding("zero"),
                                          tl: {
                                            hd: Css.position("absolute"),
                                            tl: {
                                              hd: Css.left(Css.px(4)),
                                              tl: {
                                                hd: Css.width(Css.pct(100.0)),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              tl: {
                hd: allowedValueInput,
                tl: {
                  hd: className,
                  tl: /* [] */0
                }
              }
            });
}

function GuardRailsConfigComponents$AutoGrowingInput(Props) {
  var containerClassNameOpt = Props.containerClassName;
  var classNameOpt = Props.className;
  var disabledOpt = Props.disabled;
  var hOpt = Props.height;
  var inputHeightOffsetOpt = Props.inputHeightOffset;
  var forwardInputRef = Props.inputRef;
  var onBlur = Props.onBlur;
  var onChange = Props.onChange;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var onPaste = Props.onPaste;
  var showWhitespaceOnBlurOpt = Props.showWhitespaceOnBlur;
  var placeholder = Props.placeholder;
  var errorOpt = Props.error;
  var warningOpt = Props.warning;
  var value = Props.value;
  var autoFocusOpt = Props.autoFocus;
  var containerClassName = containerClassNameOpt !== undefined ? containerClassNameOpt : Curry._1(Css.style, /* [] */0);
  var className = classNameOpt !== undefined ? classNameOpt : Curry._1(Css.style, /* [] */0);
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var h = hOpt !== undefined ? hOpt : 32;
  var inputHeightOffset = inputHeightOffsetOpt !== undefined ? inputHeightOffsetOpt : 2;
  var showWhitespaceOnBlur = showWhitespaceOnBlurOpt !== undefined ? showWhitespaceOnBlurOpt : false;
  var error = errorOpt !== undefined ? errorOpt : false;
  var warning = warningOpt !== undefined ? warningOpt : false;
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var componentInputRef = React.useRef(null);
  var inputRef = forwardInputRef !== undefined ? forwardInputRef : componentInputRef;
  var match = React.useState(function () {
        return false;
      });
  var setHasFocus = match[1];
  var hasFocus = match[0];
  var onFocus = function (param) {
    return Curry._1(setHasFocus, (function (param) {
                  return true;
                }));
  };
  var onBlur$1 = function (e) {
    Curry._1(setHasFocus, (function (param) {
            return false;
          }));
    return Belt_Option.forEach(onBlur, (function (f) {
                  return Curry._1(f, e);
                }));
  };
  KeyListener.use(undefined, undefined, undefined, (function (e) {
          if (hasFocus) {
            e.stopPropagation();
            e.preventDefault();
            return Belt_Option.forEach(onEscape, (function (cb) {
                          return Curry._1(cb, undefined);
                        }));
          }
          
        }), "Escape");
  var match$1 = value.length;
  var tmp;
  var exit = 0;
  if (match$1 !== 0 || placeholder === undefined) {
    exit = 1;
  } else {
    tmp = hasFocus || !showWhitespaceOnBlur ? placeholder : "<empty>";
  }
  if (exit === 1) {
    tmp = hasFocus || !showWhitespaceOnBlur ? value : value.replace(new RegExp("[ ]", "g"), "<space>");
  }
  var tmp$1;
  if (showWhitespaceOnBlur) {
    var tmp$2;
    if (value.length === 0) {
      tmp$2 = React.createElement("em", undefined, "<empty>");
    } else {
      var textEls = value.replace(new RegExp("[ ]", "g"), "<space>").split("<space>");
      tmp$2 = Belt_Array.reduceWithIndex(textEls, [], (function (acc, el, i) {
              acc.push(el);
              if ((textEls.length - 1 | 0) !== i) {
                acc.push(React.createElement("em", {
                          key: String(i)
                        }, "<space>"));
              }
              return acc;
            }));
    }
    tmp$1 = React.createElement("span", {
          "aria-hidden": hasFocus,
          className: Curry._1(Css.merge, {
                hd: mergedInput(inputHeightOffset, className),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: hasFocus ? Css.opacity(0.0) : Styles.emptyStyle,
                        tl: {
                          hd: hasFocus ? Css.visibility("hidden") : Styles.emptyStyle,
                          tl: /* [] */0
                        }
                      }),
                  tl: /* [] */0
                }
              }),
          onClick: (function (param) {
              return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (el) {
                            el.focus();
                            
                          }));
            })
        }, tmp$2);
  } else {
    tmp$1 = React.createElement("span", {
          className: Curry._1(Css.merge, {
                hd: mergedInput(inputHeightOffset, className),
                tl: {
                  hd: Curry._1(Css.style, {
                        hd: Css.top(Css.pxFloat(inputHeightOffset + 0.5)),
                        tl: {
                          hd: hasFocus ? Css.opacity(0.0) : Styles.emptyStyle,
                          tl: {
                            hd: hasFocus ? Css.visibility("hidden") : Styles.emptyStyle,
                            tl: /* [] */0
                          }
                        }
                      }),
                  tl: /* [] */0
                }
              })
        });
  }
  var tmp$3 = {
    ref: inputRef,
    className: Curry._1(Css.merge, {
          hd: mergedInput(inputHeightOffset, className),
          tl: {
            hd: Curry._1(Css.style, {
                  hd: hasFocus || !showWhitespaceOnBlur ? Styles.emptyStyle : Css.opacity(0.0),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }),
    autoFocus: autoFocus,
    disabled: disabled,
    value: value,
    onFocus: onFocus,
    onBlur: onBlur$1,
    onChange: (function (e) {
        return Curry._1(onChange, e.target.value);
      })
  };
  if (placeholder !== undefined) {
    tmp$3.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (onPaste !== undefined) {
    tmp$3.onPaste = Caml_option.valFromOption(onPaste);
  }
  var tmp$4 = Belt_Option.map(onEnter, (function (onEnter, $$event) {
          if ($$event.key === "Enter") {
            return Curry._1(onEnter, undefined);
          }
          
        }));
  if (tmp$4 !== undefined) {
    tmp$3.onKeyUp = Caml_option.valFromOption(tmp$4);
  }
  return React.createElement("span", {
              className: container(h, disabled, containerClassName, warning, error),
              disabled: disabled,
              onClick: (function (param) {
                  return Belt_Option.forEach(Caml_option.nullable_to_opt(inputRef.current), (function (el) {
                                el.focus();
                                
                              }));
                })
            }, React.createElement("div", {
                  "aria-hidden": true,
                  className: hiddenSpan(inputHeightOffset, className)
                }, tmp), tmp$1, React.createElement("input", tmp$3));
}

var AutoGrowingInput = {
  container: container,
  allowedValueInput: allowedValueInput,
  hiddenSpan: hiddenSpan,
  mergedInput: mergedInput,
  make: GuardRailsConfigComponents$AutoGrowingInput
};

function GuardRailsConfigComponents$PredefinedValue(Props) {
  var addNewValue = Props.addNewValue;
  var $$case = Props.case;
  var disabledOpt = Props.disabled;
  var value = Props.value;
  var setValue = Props.setValue;
  var removeValue = Props.removeValue;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("flex"),
                    tl: {
                      hd: Css.alignItems("center"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.marginTop(Css.px(2)),
                        tl: {
                          hd: Css.marginBottom(Css.px(-2)),
                          tl: /* [] */0
                        }
                      })
                }, React.createElement(Button.make, {
                      disabled: disabled,
                      icon: "close",
                      onClick: (function (param) {
                          return Curry._1(removeValue, undefined);
                        }),
                      size: "tiny",
                      style: "ghost"
                    })), React.createElement(GuardRailsConfigComponents$AutoGrowingInput, {
                  className: Curry._1(Css.style, {
                        hd: Css.fontSize(Styles.FontSize.small),
                        tl: {
                          hd: Css.color(Styles.Color.light11),
                          tl: /* [] */0
                        }
                      }),
                  disabled: disabled,
                  height: 22,
                  inputHeightOffset: 1,
                  onBlur: (function (param) {
                      return Curry._1(setValue, Case.to_($$case, value));
                    }),
                  onChange: (function (newValue) {
                      return Curry._1(setValue, GuardRailsConfigUtils.getCasedValue($$case, newValue));
                    }),
                  onEnter: (function (param) {
                      Curry._1(setValue, Case.to_($$case, value));
                      return Curry._1(addNewValue, undefined);
                    }),
                  showWhitespaceOnBlur: true,
                  placeholder: "clicked",
                  value: value
                }));
}

var PredefinedValue = {
  make: GuardRailsConfigComponents$PredefinedValue
};

function MakeResizableSelect(C) {
  var select = function (absWidth) {
    return {
            hd: absWidth !== undefined ? Css.maxWidth(Css.px(absWidth)) : Styles.emptyStyle,
            tl: {
              hd: Css.color(Styles.Color.light11),
              tl: /* [] */0
            }
          };
  };
  var GuardRailsConfigComponents$MakeResizableSelect = function (Props) {
    var disabledOpt = Props.disabled;
    var enableDisabledItemsOpt = Props.enableDisabledItems;
    var lightningOnDisabledItemsOpt = Props.lightningOnDisabledItems;
    var toLabelOverride = Props.toLabelOverride;
    var onSelect = Props.onSelect;
    var options = Props.options;
    var value = Props.value;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var enableDisabledItems = enableDisabledItemsOpt !== undefined ? enableDisabledItemsOpt : false;
    var lightningOnDisabledItems = lightningOnDisabledItemsOpt !== undefined ? lightningOnDisabledItemsOpt : false;
    var sizeRef = React.useRef(null);
    var match = React.useState(function () {
          return Belt_Option.map(Caml_option.nullable_to_opt(sizeRef.current), (function (el) {
                        return el.scrollWidth;
                      }));
        });
    var setAbsWidth = match[1];
    var absWidth = match[0];
    var valueToLabel = Belt_Option.getWithDefault(toLabelOverride, C.valueToLabel);
    var getLabel = function (option) {
      if (option.NAME === "Label") {
        return Curry._1(valueToLabel, option.VAL);
      } else {
        return Curry._1(valueToLabel, option.VAL) + (
                lightningOnDisabledItems ? " ⚡" : ""
              );
      }
    };
    var findOptionLabel = function (value) {
      return Belt_Option.mapWithDefault(Belt_Array.getBy(options, (function (option) {
                        return Caml_obj.caml_equal(option.VAL, value);
                      })), Curry._1(valueToLabel, value), getLabel);
    };
    React.useEffect((function () {
            var el = sizeRef.current;
            if (!(el == null)) {
              Curry._1(setAbsWidth, (function (param) {
                      return el.scrollWidth;
                    }));
            }
            
          }), [findOptionLabel(value)]);
    return React.createElement("span", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    })
              }, React.createElement("span", {
                    ref: sizeRef,
                    className: Curry._1(Css.merge, {
                          hd: Curry._1(Css.style, Styles.maxLines(1)),
                          tl: {
                            hd: Select.componentStyle(Css.px(400), disabled),
                            tl: {
                              hd: Curry._1(Css.style, {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Belt_Option.isSome(absWidth) ? Css.visibility("hidden") : Styles.emptyStyle,
                                      tl: {
                                        hd: Css.paddingRight(Css.px(34)),
                                        tl: {
                                          hd: Css.border(Css.px(1), "solid", "transparent"),
                                          tl: {
                                            hd: Css.pointerEvents("none"),
                                            tl: {
                                              hd: Css.width("maxContent"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }
                        })
                  }, findOptionLabel(value)), React.createElement(Select.make, {
                    additionalStyles: select(absWidth),
                    disabled: disabled,
                    onSelect: (function (v) {
                        return Curry._1(onSelect, Belt_Option.getExn(Curry._1(C.valueFromString, v)));
                      }),
                    options: Belt_Array.map(options, (function (option) {
                            if (option.NAME === "Label") {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: getLabel(option)
                                      },
                                      Curry._1(C.valueToString, option.VAL)
                                    ];
                            }
                            var value = option.VAL;
                            if (enableDisabledItems) {
                              return [
                                      {
                                        NAME: "Label",
                                        VAL: getLabel(option)
                                      },
                                      Curry._1(C.valueToString, value)
                                    ];
                            } else {
                              return [
                                      {
                                        NAME: "Disabled",
                                        VAL: getLabel(option)
                                      },
                                      Curry._1(C.valueToString, value)
                                    ];
                            }
                          })),
                    value: Curry._1(C.valueToString, value)
                  }));
  };
  return {
          select: select,
          valueToLabel: C.valueToLabel,
          valueToString: C.valueToString,
          valueFromString: C.valueFromString,
          make: GuardRailsConfigComponents$MakeResizableSelect
        };
}

export {
  Style ,
  GuardRailContainer ,
  GuardRailSectionTitle ,
  GuardRailSection ,
  GuardRailSubSection ,
  GuardRailRow ,
  NameComponentDetails ,
  AdvancedNameConventions ,
  BasicNameConventions ,
  AutoGrowingInput ,
  PredefinedValue ,
  MakeResizableSelect ,
  
}
/* row Not a pure module */
