// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@mantine/core";

var tooltip = Curry._1(Css.style, {
      hd: Css.paddingTop(Css.px(8)),
      tl: {
        hd: Css.paddingRight(Css.px(12)),
        tl: {
          hd: Css.paddingLeft(Css.px(12)),
          tl: {
            hd: Css.paddingBottom(Css.px(8)),
            tl: {
              hd: Css.borderRadius(Css.px(8)),
              tl: {
                hd: Css.backgroundColor(Styles.Color.dark02),
                tl: {
                  hd: Css.color(Styles.Color.dark12),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var Style = {
  tooltip: tooltip
};

function SimpleTooltip$Basic(Props) {
  var arrowSizeOpt = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var delayInOpt = Props.delayIn;
  var disabledOpt = Props.disabled;
  var forcedOpened = Props.opened;
  var customMaxWidthOpt = Props.maxWidth;
  var offset = Props.offset;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var customPositionOpt = Props.position;
  var preventDelayOutOpt = Props.preventDelayOut;
  var tooltip$1 = Props.tooltip;
  var containerStyles = Props.containerStyles;
  var children = Props.children;
  var arrowSize = arrowSizeOpt !== undefined ? arrowSizeOpt : 12;
  var delayIn = delayInOpt !== undefined ? delayInOpt : 500;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var customMaxWidth = customMaxWidthOpt !== undefined ? customMaxWidthOpt : 350;
  var customPosition = customPositionOpt !== undefined ? customPositionOpt : "bottom";
  var preventDelayOut = preventDelayOutOpt !== undefined ? preventDelayOutOpt : false;
  var match = Hooks.useDisclosure(Belt_Option.getWithDefault(forcedOpened, false));
  var action = match[1];
  var opened = match[0];
  var tooltipOpenTimeout = React.useRef(undefined);
  var tooltipCloseTimeout = React.useRef(undefined);
  var tooltipSendOpenEventTimeout = React.useRef(undefined);
  Hooks.useDidUpdate2((function (param) {
          if (Belt_Option.getWithDefault(forcedOpened, false)) {
            Curry._1(action.open_, undefined);
            return ;
          } else {
            if (opened && !disabled) {
              tooltipSendOpenEventTimeout.current = Caml_option.some(setTimeout((function (param) {
                          Belt_Option.forEach(onShow, (function (cb) {
                                  return Curry._1(cb, undefined);
                                }));
                          tooltipSendOpenEventTimeout.current = undefined;
                          
                        }), 600));
            }
            if (!opened && !disabled) {
              Belt_Option.forEach(tooltipSendOpenEventTimeout.current, (function (prim) {
                      clearTimeout(prim);
                      
                    }));
              if (tooltipSendOpenEventTimeout.current === undefined) {
                Belt_Option.forEach(onHide, (function (cb) {
                        return Curry._1(cb, undefined);
                      }));
              }
              
            }
            return (function (param) {
                      Belt_Option.forEach(tooltipSendOpenEventTimeout.current, (function (prim) {
                              clearTimeout(prim);
                              
                            }));
                      Belt_Option.forEach(tooltipCloseTimeout.current, (function (prim) {
                              clearTimeout(prim);
                              
                            }));
                      return Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
                                    clearTimeout(prim);
                                    
                                  }));
                    });
          }
        }), [
        opened,
        forcedOpened
      ]);
  var onMouseEnter = function (param) {
    Belt_Option.forEach(tooltipCloseTimeout.current, (function (prim) {
            clearTimeout(prim);
            
          }));
    if (!disabled) {
      tooltipOpenTimeout.current = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(action.open_, undefined);
                }), delayIn));
      return ;
    }
    
  };
  var onMouseLeave = function (param) {
    Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
            clearTimeout(prim);
            
          }));
    if (Belt_Option.getWithDefault(forcedOpened, false)) {
      return ;
    } else if (preventDelayOut) {
      if (!disabled && opened) {
        return Curry._1(action.close, undefined);
      } else {
        return ;
      }
    } else if (!disabled && opened) {
      tooltipCloseTimeout.current = Caml_option.some(setTimeout((function (param) {
                  return Curry._1(action.close, undefined);
                }), 250));
      return ;
    } else {
      return ;
    }
  };
  Hooks.useDidUpdate1((function (param) {
          if (disabled) {
            Belt_Option.forEach(tooltipOpenTimeout.current, (function (prim) {
                    clearTimeout(prim);
                    
                  }));
            Curry._1(action.close, undefined);
          }
          
        }), [disabled]);
  var tmp = {
    arrowSize: arrowSize,
    arrowStyles: {
      hd: Css.backgroundColor(Styles.Color.dark02),
      tl: {
        hd: Css.boxSizing("unset"),
        tl: {
          hd: Css.borderColor("transparent"),
          tl: {
            hd: Css.zIndex(-1),
            tl: /* [] */0
          }
        }
      }
    },
    dropdownStyles: {
      hd: Css.borderRadius(Css.px(6)),
      tl: {
        hd: Css.backgroundColor("transparent"),
        tl: {
          hd: Css.boxShadow(Styles.Shadow.box),
          tl: /* [] */0
        }
      }
    },
    opened: opened && !disabled,
    position: customPosition,
    withArrow: true,
    zIndex: Styles.ZIndex.aboveAll + 1 | 0,
    children: null
  };
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  return React.createElement(Mantine.Popover.make, tmp, React.createElement(Core.Popover.Target, {
                  children: React.createElement("span", {
                        className: Curry._1(Css.merge, {
                              hd: Curry._1(Css.style, {
                                    hd: Css.display("flex"),
                                    tl: {
                                      hd: Css.width("maxContent"),
                                      tl: /* [] */0
                                    }
                                  }),
                              tl: {
                                hd: Belt_Option.getWithDefault(containerStyles, Curry._1(Css.style, /* [] */0)),
                                tl: /* [] */0
                              }
                            }),
                        onClick: (function (param) {
                            return Curry._1(action.toggle, undefined);
                          }),
                        onMouseEnter: onMouseEnter,
                        onMouseLeave: onMouseLeave
                      }, children)
                }), React.createElement(Mantine.Popover.Dropdown.make, {
                  children: React.createElement("div", {
                        className: Curry._1(Css.merge, {
                              hd: tooltip,
                              tl: {
                                hd: Curry._1(Css.style, {
                                      hd: Css.maxWidth(Css.px(customMaxWidth)),
                                      tl: /* [] */0
                                    }),
                                tl: /* [] */0
                              }
                            }),
                        onMouseEnter: (function (param) {
                            if (preventDelayOut) {
                              return ;
                            } else {
                              return onMouseEnter(undefined);
                            }
                          }),
                        onMouseLeave: (function (param) {
                            if (preventDelayOut) {
                              return ;
                            } else {
                              return onMouseLeave(undefined);
                            }
                          })
                      }, tooltip$1.NAME === "string" ? React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              children: StringExt.widowless(tooltip$1.VAL)
                            }) : tooltip$1.VAL)
                }));
}

var Basic = {
  make: SimpleTooltip$Basic
};

function SimpleTooltip$WithComponent(Props) {
  var arrowSize = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var containerStyles = Props.containerStyles;
  var delayIn = Props.delayIn;
  var disabled = Props.disabled;
  var maxWidth = Props.maxWidth;
  var offset = Props.offset;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var position = Props.position;
  var preventDelayOut = Props.preventDelayOut;
  var tooltip = Props.tooltip;
  var children = Props.children;
  var tmp = {
    tooltip: {
      NAME: "component",
      VAL: tooltip
    },
    children: children
  };
  if (arrowSize !== undefined) {
    tmp.arrowSize = Caml_option.valFromOption(arrowSize);
  }
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delayIn !== undefined) {
    tmp.delayIn = Caml_option.valFromOption(delayIn);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onHide !== undefined) {
    tmp.onHide = Caml_option.valFromOption(onHide);
  }
  if (onShow !== undefined) {
    tmp.onShow = Caml_option.valFromOption(onShow);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (preventDelayOut !== undefined) {
    tmp.preventDelayOut = Caml_option.valFromOption(preventDelayOut);
  }
  if (containerStyles !== undefined) {
    tmp.containerStyles = Caml_option.valFromOption(containerStyles);
  }
  return React.createElement(SimpleTooltip$Basic, tmp);
}

var WithComponent = {
  make: SimpleTooltip$WithComponent
};

function SimpleTooltip(Props) {
  var arrowSize = Props.arrowSize;
  var arrowOffset = Props.arrowOffset;
  var containerStyles = Props.containerStyles;
  var delayIn = Props.delayIn;
  var disabled = Props.disabled;
  var maxWidth = Props.maxWidth;
  var offset = Props.offset;
  var opened = Props.opened;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var position = Props.position;
  var preventDelayOut = Props.preventDelayOut;
  var tooltip = Props.tooltip;
  var children = Props.children;
  var tmp = {
    tooltip: {
      NAME: "string",
      VAL: tooltip
    },
    children: children
  };
  if (arrowSize !== undefined) {
    tmp.arrowSize = Caml_option.valFromOption(arrowSize);
  }
  if (arrowOffset !== undefined) {
    tmp.arrowOffset = Caml_option.valFromOption(arrowOffset);
  }
  if (delayIn !== undefined) {
    tmp.delayIn = Caml_option.valFromOption(delayIn);
  }
  if (disabled !== undefined) {
    tmp.disabled = Caml_option.valFromOption(disabled);
  }
  if (opened !== undefined) {
    tmp.opened = Caml_option.valFromOption(opened);
  }
  if (maxWidth !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  if (offset !== undefined) {
    tmp.offset = Caml_option.valFromOption(offset);
  }
  if (onHide !== undefined) {
    tmp.onHide = Caml_option.valFromOption(onHide);
  }
  if (onShow !== undefined) {
    tmp.onShow = Caml_option.valFromOption(onShow);
  }
  if (position !== undefined) {
    tmp.position = Caml_option.valFromOption(position);
  }
  if (preventDelayOut !== undefined) {
    tmp.preventDelayOut = Caml_option.valFromOption(preventDelayOut);
  }
  if (containerStyles !== undefined) {
    tmp.containerStyles = Caml_option.valFromOption(containerStyles);
  }
  return React.createElement(SimpleTooltip$Basic, tmp);
}

var make = SimpleTooltip;

export {
  Style ,
  Basic ,
  WithComponent ,
  make ,
  
}
/* tooltip Not a pure module */
