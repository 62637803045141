// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Case from "../../bs-case/src/case.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Toast from "./Toast.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as Router from "./Router.mjs";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Switch from "./Switch.mjs";
import * as Mantine from "./Mantine.mjs";
import Shortid from "shortid";
import * as AvoUtils from "../../shared/utils/AvoUtils.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as AvoLimits from "./AvoLimits.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as NameUtils from "../../shared/utils/NameUtils.mjs";
import * as StringExt from "./StringExt.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TextButton from "./TextButton.mjs";
import * as AnalyticsRe from "./analyticsRe.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "./KeyListener.mjs";
import * as CmdKProvider from "./CmdKProvider.mjs";
import * as SearchSelect from "./SearchSelect.mjs";
import * as FirebaseUtils from "./firebaseUtils.mjs";
import * as SimpleTooltip from "./SimpleTooltip.mjs";
import * as AnalyticsUtils from "./analyticsUtils.mjs";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";
import * as WorkspaceContext from "./WorkspaceContext.mjs";
import * as GlobalSendContext from "./GlobalSendContext.mjs";
import * as StringSimilarity from "string-similarity";
import * as DiscrepancyContext from "./DiscrepancyContext.mjs";
import * as EventNameComponent from "../../shared/models/EventNameComponent.mjs";
import * as SendActionsContext from "./SendActionsContext.mjs";
import * as ValidationConfigV2 from "./guardrails/ValidationConfigV2.mjs";
import * as SchemaBundleContext from "./SchemaBundleContext.mjs";
import * as GuardRailsConfigUtils from "./guardrails/GuardRailsConfigUtils.mjs";
import * as GuardRailsConfigComponents from "./guardrails/GuardRailsConfigComponents.mjs";
import * as EventNameComponentCollection from "./EventNameComponentCollection.mjs";

var targetContainerStyles = Curry._1(Css.style, {
      hd: Css.label("name-component"),
      tl: {
        hd: Css.background("transparent"),
        tl: {
          hd: Css.margin(Css.px(-4)),
          tl: {
            hd: Css.padding(Css.px(4)),
            tl: {
              hd: Css.outline(Css.px(0), "solid", "transparent"),
              tl: {
                hd: Css.transitions({
                      hd: Css_Legacy_Core.Transition.shorthand({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "outline"),
                      tl: {
                        hd: Css_Legacy_Core.Transition.shorthand({
                              NAME: "ms",
                              VAL: Styles.Duration.$$short
                            }, undefined, undefined, "padding"),
                        tl: {
                          hd: Css_Legacy_Core.Transition.shorthand({
                                NAME: "ms",
                                VAL: Styles.Duration.$$short
                              }, undefined, undefined, "margin"),
                          tl: /* [] */0
                        }
                      }
                    }),
                tl: {
                  hd: Css.hover({
                        hd: Css.position("relative"),
                        tl: {
                          hd: Css.zIndex(1),
                          tl: {
                            hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                            tl: {
                              hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                              tl: /* [] */0
                            }
                          }
                        }
                      }),
                  tl: {
                    hd: Css.focusWithin({
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                            tl: {
                              hd: Css.marginLeft(Css.px(0)),
                              tl: {
                                hd: Css.marginRight(Css.px(0)),
                                tl: {
                                  hd: Css.padding4(Css.px(4), Css.px(18), Css.px(4), Css.px(4)),
                                  tl: {
                                    hd: Css.selector(" .allowed-value__chevron-down", {
                                          hd: Css.opacity(1.0),
                                          tl: {
                                            hd: Css.width(Css.px(16)),
                                            tl: {
                                              hd: Css.pointerEvents("none"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }),
                    tl: /* [] */0
                  }
                }
              }
            }
          }
        }
      }
    });

var targetContentStyles = Curry._1(Css.style, {
      hd: Css.zIndex(0),
      tl: {
        hd: Css.padding("zero"),
        tl: {
          hd: Css.borderRadius(Styles.Border.radius),
          tl: /* [] */0
        }
      }
    });

var targetOpenedStyles = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: {
        hd: Css.backgroundColor(Styles.Color.white),
        tl: {
          hd: Css.zIndex(1),
          tl: {
            hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
            tl: {
              hd: Css.outline(Css.px(1), "solid", Styles.Color.red),
              tl: {
                hd: Css.marginLeft(Css.px(0)),
                tl: {
                  hd: Css.marginRight(Css.px(0)),
                  tl: {
                    hd: Css.padding4(Css.px(4), Css.px(18), Css.px(4), Css.px(4)),
                    tl: {
                      hd: Css.selector(" .allowed-value__chevron-down", {
                            hd: Css.opacity(1.0),
                            tl: {
                              hd: Css.width(Css.px(16)),
                              tl: {
                                hd: Css.pointerEvents("none"),
                                tl: /* [] */0
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var dropdownContainerStyles_0 = Css.transforms({
      hd: Css.translateY(Css.px(-6)),
      tl: {
        hd: Css.translateX(Css.px(-4)),
        tl: /* [] */0
      }
    });

var dropdownContainerStyles = {
  hd: dropdownContainerStyles_0,
  tl: /* [] */0
};

var buttonStyles = Curry._1(Css.merge, {
      hd: Curry._1(Css.style, Styles.buttonReset),
      tl: {
        hd: Curry._1(Css.style, {
              hd: Css.position("relative"),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.width(Css.pct(100.0)),
                      tl: {
                        hd: Css.focus({
                              hd: Css.outlineWidth("zero"),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }),
        tl: /* [] */0
      }
    });

var chevronStyles = Curry._1(Css.style, {
      hd: Css.position("absolute"),
      tl: {
        hd: Css.right(Css.px(-16)),
        tl: {
          hd: Css.top(Css.px(2)),
          tl: /* [] */0
        }
      }
    });

function AdvancedNameInput$AllowedValuesSelect(Props) {
  var allowedValues = Props.allowedValues;
  var autoFocus = Props.autoFocus;
  var $$case = Props.case;
  var eventEdit = Props.eventEdit;
  var name = Props.name;
  var onChange = Props.onChange;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var role = Props.role;
  var validation = Props.validation;
  var value = Props.value;
  var actionString = eventEdit === "Update" ? "updated" : "created";
  var buttonRef = React.useRef(null);
  var currentValue = value !== undefined ? (
      Belt_Array.every(allowedValues, (function (allowedValue) {
              return allowedValue !== value;
            })) ? [{
            value: value,
            label: value,
            selected: true,
            message: role === "Admin" ? "This value will be added to the predefined values list of \"" + name + "\" when the event is " + actionString + "." : "This value is not a valid \"" + name + "\" value.",
            valid: role === "Admin"
          }] : []
    ) : [];
  var allowedValues$1 = Belt_Array.map(Belt_SetString.toArray(Belt_SetString.fromArray(allowedValues)), (function (allowedValue) {
          return {
                  value: allowedValue,
                  label: allowedValue,
                  selected: Belt_Option.mapWithDefault(value, false, (function (value) {
                          return value === allowedValue;
                        })),
                  valid: true
                };
        }));
  var tmp = {
    items: Belt_Array.concat(currentValue, allowedValues$1),
    maxItemsBeforeOfferingSearch: 0,
    arbitraryChoice: role === "Admin" ? ({
          NAME: "withMessage",
          VAL: (function (searchInput) {
              return "\"" + searchInput + "\" will be added to the predefined values list of \"" + name + "\" when the event is " + actionString;
            })
        }) : ({
          NAME: "disabled",
          VAL: (function (param) {
              return "Only workspace admins can add allowed values to event name fields";
            })
        }),
    onChange: (function (v) {
        return Curry._1(onChange, v);
      }),
    targetContainerStyles: targetContainerStyles,
    targetContentStyles: targetContentStyles,
    targetOpenedStyles: targetOpenedStyles,
    dropdownContainerStyles: dropdownContainerStyles,
    dropdownItemStyles: (function (selected) {
        return Curry._1(Css.style, {
                    hd: Css.color(Styles.Color.light10),
                    tl: {
                      hd: selected ? Css.fontWeight(Styles.FontWeight.semi) : Styles.emptyStyle,
                      tl: /* [] */0
                    }
                  });
      }),
    children: React.createElement("button", {
          ref: buttonRef,
          className: buttonStyles,
          autoFocus: autoFocus
        }, React.createElement($$Text.make, {
              size: "Medium",
              color: value !== undefined ? (
                  validation === "valid" || role === "Admin" ? Styles.Color.light11 : Styles.Color.darkOrange
                ) : Styles.Color.light07,
              children: Belt_Option.getWithDefault(value, Case.to_($$case, name))
            }), React.createElement("span", {
              className: chevronStyles + " allowed-value__chevron-down"
            }, React.createElement(Spacer.make, {
                  width: 4,
                  grow: 1.0
                }), React.createElement(Icon.make, {
                  type_: "chevronDown",
                  size: "small",
                  color: Styles.Color.light08
                })))
  };
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEscape !== undefined) {
    tmp.onEscape = Caml_option.valFromOption(onEscape);
  }
  return React.createElement(SearchSelect.make, tmp);
}

function AdvancedNameInput$Component(Props) {
  var allowedValues = Props.allowedValues;
  var autoFocus = Props.autoFocus;
  var $$case = Props.case;
  var eventEdit = Props.eventEdit;
  var inputRef = Props.inputRef;
  var onPaste = Props.onPaste;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var name = Props.name;
  var onChange = Props.onChange;
  var role = Props.role;
  var validation = Props.validation;
  var value = Props.value;
  var hasBlockedValue = Props.hasBlockedValue;
  if (allowedValues.length !== 0) {
    var tmp = {
      allowedValues: allowedValues,
      autoFocus: autoFocus,
      case: $$case,
      eventEdit: eventEdit,
      name: name,
      onChange: onChange,
      role: role,
      validation: validation,
      value: value
    };
    if (onEnter !== undefined) {
      tmp.onEnter = Caml_option.valFromOption(onEnter);
    }
    if (onEscape !== undefined) {
      tmp.onEscape = Caml_option.valFromOption(onEscape);
    }
    return React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.display("inlineBlock"),
                        tl: {
                          hd: Css.focusWithin({
                                hd: Css.zIndex(1),
                                tl: /* [] */0
                              }),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement("span", {
                    className: Curry._1(Css.style, {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.top(Css.px(-22)),
                            tl: {
                              hd: Css.left("zero"),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.textOverflow("ellipsis"),
                                    tl: {
                                      hd: Css.display("inlineGrid"),
                                      tl: {
                                        hd: Css.maxWidth({
                                              NAME: "subtract",
                                              VAL: [
                                                {
                                                  NAME: "subtract",
                                                  VAL: [
                                                    Css.vw(100.0),
                                                    Css.pct(100.0)
                                                  ]
                                                },
                                                Css.px(20)
                                              ]
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        })
                  }, React.createElement($$Text.make, {
                        element: "Span",
                        size: "Tiny",
                        singleLine: true,
                        color: Styles.Color.light09,
                        title: name,
                        children: name
                      })), React.createElement(AdvancedNameInput$AllowedValuesSelect, tmp));
  }
  var tmp$1 = {
    containerClassName: Curry._1(Css.style, {
          hd: Css.label("name-component"),
          tl: {
            hd: Css.display("inlineBlock"),
            tl: {
              hd: Css.margin(Css.px(-6)),
              tl: {
                hd: Css.marginTop(Css.px(-5)),
                tl: {
                  hd: Css.padding(Css.px(6)),
                  tl: {
                    hd: Css.paddingTop(Css.px(7)),
                    tl: {
                      hd: Css.transition({
                            NAME: "ms",
                            VAL: Styles.Duration.$$short
                          }, undefined, undefined, "all"),
                      tl: {
                        hd: Css.focusWithin({
                              hd: Css.backgroundColor(Styles.Color.white),
                              tl: {
                                hd: Css.outline(Css.px(1), "solid", Styles.Color.light08),
                                tl: {
                                  hd: Css.margin2(Css.px(-6), Css.px(0)),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: {
                          hd: Css.hover({
                                hd: Css.zIndex(1),
                                tl: {
                                  hd: Css.backgroundColor(Styles.Color.setAlpha(Styles.Color.white, 0.85)),
                                  tl: {
                                    hd: Css.outline(Css.px(1), "solid", Styles.Color.light07),
                                    tl: /* [] */0
                                  }
                                }
                              }),
                          tl: {
                            hd: Css.outline("zero", "solid", "transparent"),
                            tl: {
                              hd: Css.marginBottom(Css.px(-20)),
                              tl: {
                                hd: Css.paddingBottom(Css.pxFloat(20.5)),
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }),
    className: Curry._1(Css.merge, {
          hd: Curry._1(Css.style, {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.left(Css.px(6)),
                    tl: {
                      hd: Css.top(Css.px(5)),
                      tl: {
                        hd: Css.fontSize(Css.px(14)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          tl: {
            hd: hasBlockedValue ? Curry._1(Css.style, {
                    hd: Css.textDecorationLine("underline"),
                    tl: {
                      hd: Css.textDecorationStyle("wavy"),
                      tl: {
                        hd: Css.textDecorationColor(Styles.Color.avoPink),
                        tl: {
                          hd: Css.unsafe("textDecorationThickness", "0.8px"),
                          tl: /* [] */0
                        }
                      }
                    }
                  }) : Curry._1(Css.style, /* [] */0),
            tl: /* [] */0
          }
        }),
    height: 24,
    onBlur: (function (param) {
        if (value !== undefined) {
          return Curry._1(onChange, Case.to_($$case, value));
        }
        
      }),
    onChange: (function (newValue) {
        if (newValue !== undefined) {
          return Curry._1(onChange, GuardRailsConfigUtils.getCasedValue($$case, newValue));
        } else {
          return Curry._1(onChange, newValue);
        }
      }),
    onPaste: (function (e) {
        return Belt_Option.forEach(onPaste, (function (f) {
                      return Curry._1(f, (e.clipboardData.getData('text/plain')));
                    }));
      }),
    placeholder: Case.to_($$case, name),
    value: Belt_Option.getWithDefault(value, ""),
    autoFocus: autoFocus
  };
  if (inputRef !== undefined) {
    tmp$1.inputRef = Caml_option.valFromOption(inputRef);
  }
  if (onEnter !== undefined) {
    tmp$1.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEscape !== undefined) {
    tmp$1.onEscape = Caml_option.valFromOption(onEscape);
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.display("inlineBlock"),
                    tl: {
                      hd: Css.position("relative"),
                      tl: {
                        hd: Css.marginBottom(Css.px(-2)),
                        tl: /* [] */0
                      }
                    }
                  })
            }, React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.display("inlineGrid"),
                        tl: {
                          hd: Css.position("absolute"),
                          tl: {
                            hd: Css.left("zero"),
                            tl: {
                              hd: Css.top(Css.px(-23)),
                              tl: {
                                hd: Css.whiteSpace("nowrap"),
                                tl: {
                                  hd: Css.overflow("hidden"),
                                  tl: {
                                    hd: Css.textOverflow("ellipsis"),
                                    tl: {
                                      hd: Css.display("inlineGrid"),
                                      tl: {
                                        hd: Css.maxWidth({
                                              NAME: "subtract",
                                              VAL: [
                                                {
                                                  NAME: "subtract",
                                                  VAL: [
                                                    Css.vw(100.0),
                                                    Css.pct(100.0)
                                                  ]
                                                },
                                                Css.px(20)
                                              ]
                                            }),
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      })
                }, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Tiny",
                      singleLine: true,
                      color: Styles.Color.light09,
                      title: name,
                      children: name
                    })), React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingRight(Css.px(0)),
                        tl: /* [] */0
                      })
                }, React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, tmp$1)));
}

function onSave(nameComponents, guardrailsEnabled, name, schemaId, values, onChange, onError, onReset) {
  var match;
  if (guardrailsEnabled) {
    var updatedEventName = Belt_Array.map(values, (function (value) {
              if (value.NAME === "separator") {
                return value.VAL;
              } else {
                return Belt_Option.getWithDefault(value.VAL.value, "");
              }
            })).join("");
    var eventNameComponents = Belt_Array.keepMap(values, (function (value) {
            if (value.NAME === "separator") {
              return ;
            }
            var match = value.VAL;
            return EventNameComponent.make(match.name, Belt_Option.getWithDefault(match.value, ""));
          }));
    match = [
      eventNameComponents,
      updatedEventName
    ];
  } else {
    match = [
      EventNameComponent.getEventNameComponentFromName(name, nameComponents),
      name
    ];
  }
  var eventName = match[1];
  var eventNameComponents$1 = match[0];
  if (eventNameComponents$1 !== undefined) {
    return EventNameComponentCollection.setEventNameComponent(Firebase.app(undefined).firestore(), schemaId, eventName, eventNameComponents$1, ValidationConfigV2.NameComponent.getNameConfigAsString(nameComponents)).then(function (res) {
                if (res.TAG === /* Ok */0) {
                  return Promise.resolve(Curry._4(onChange, eventName, eventNameComponents$1, nameComponents, onReset));
                } else {
                  return Promise.resolve(Curry._1(onError, res._0));
                }
              });
  } else {
    return Promise.resolve(Curry._4(onChange, eventName, [], nameComponents, onReset));
  }
}

function AdvancedNameInput$Section(Props) {
  var hOpt = Props.h;
  var vOpt = Props.v;
  var customBackgroundColorOpt = Props.backgroundColor;
  var children = Props.children;
  var h = hOpt !== undefined ? hOpt : 32;
  var v = vOpt !== undefined ? vOpt : 0;
  var customBackgroundColor = customBackgroundColorOpt !== undefined ? customBackgroundColorOpt : Styles.Color.white;
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.padding2(Css.px(v), Css.px(h)),
                    tl: {
                      hd: Css.backgroundColor(customBackgroundColor),
                      tl: /* [] */0
                    }
                  })
            }, children);
}

function AdvancedNameInput$Separator(Props) {
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.height(Css.px(1)),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.light04),
                      tl: /* [] */0
                    }
                  })
            });
}

function isAllowedValueValid(componentValue, allowedValues) {
  if (allowedValues.length !== 0) {
    return Belt_Array.some(allowedValues, (function (allowedValue) {
                  return allowedValue === componentValue;
                }));
  } else {
    return true;
  }
}

function isBlockedValue(componentValue, blockedValues) {
  if (blockedValues.length === 0) {
    return false;
  } else {
    return Belt_Array.someU(blockedValues, (function (blockedValue) {
                  return componentValue.includes(blockedValue);
                }));
  }
}

function getInputValidation(componentValue, $$case, allowedValues, blockedValues) {
  if (componentValue === undefined) {
    return "empty";
  }
  if (componentValue.trim() === "") {
    return "empty";
  }
  if (allowedValues.length === 0 && blockedValues.length === 0) {
    if (componentValue.trim() !== componentValue) {
      return "includesTrailingWhitespace";
    } else if (NameUtils.isCorrectCase($$case, componentValue)) {
      return "valid";
    } else {
      return "invalidCase";
    }
  }
  if (blockedValues.length === 0) {
    if (isAllowedValueValid(componentValue, allowedValues)) {
      return "valid";
    } else {
      return "allowedValuesConflict";
    }
  }
  if (!NameUtils.isCorrectCase($$case, componentValue)) {
    return "invalidCase";
  }
  var blockedWordsUsed = Belt_Array.keepMapU(blockedValues, (function (blockedValue) {
          if (componentValue.includes(blockedValue)) {
            return blockedValue;
          }
          
        }));
  if (blockedWordsUsed.length !== 0) {
    return {
            NAME: "blockedValueConflict",
            VAL: blockedWordsUsed
          };
  } else {
    return "valid";
  }
}

function getValues(currentName, currentStructure, nameComponents) {
  var currentStructure$1 = currentStructure !== undefined ? currentStructure : Belt_Option.flatMap(currentName, (function (name) {
            return EventNameComponent.getEventNameComponentFromName(name, nameComponents);
          }));
  return Belt_Array.map(nameComponents, (function (nameComponent) {
                if (nameComponent.NAME === "separator") {
                  return {
                          NAME: "separator",
                          VAL: nameComponent.VAL
                        };
                }
                var match = nameComponent.VAL;
                var blockedValues = match.blockedValues;
                var allowedValues = match.allowedValues;
                var $$case = match.case;
                var name = match.name;
                var value = Belt_Option.flatMap(currentStructure$1, (function (eventNameComponents) {
                        var component = Belt_Array.getBy(eventNameComponents, (function (component) {
                                return component.componentName === name;
                              }));
                        return Belt_Option.map(component, (function (component) {
                                      return component.componentValue;
                                    }));
                      }));
                return {
                        NAME: "nameBuildingBlock",
                        VAL: {
                          name: name,
                          value: value,
                          validation: getInputValidation(value, Curry._1(ValidationConfigV2.Value.getCase, $$case.value), Curry._1(ValidationConfigV2.Value.getAllowedValues, allowedValues.value), Curry._1(ValidationConfigV2.Value.getBlockedValues, blockedValues.value)),
                          case: Curry._1(ValidationConfigV2.Value.getCase, $$case.value),
                          allowedValues: Curry._1(ValidationConfigV2.Value.getAllowedValues, allowedValues.value),
                          blockedValues: Curry._1(ValidationConfigV2.Value.getBlockedValues, blockedValues.value)
                        }
                      };
              }));
}

function getNameFromStructure(values) {
  return Belt_Array.map(values, (function (value) {
                  if (value.NAME === "separator") {
                    return value.VAL;
                  } else {
                    return Belt_Option.getWithDefault(value.VAL.value, "");
                  }
                })).join("");
}

function getSimpleNameFromStructure(values) {
  if (Belt_Array.every(values, (function (value) {
            if (value.NAME === "separator") {
              return true;
            }
            var match = value.VAL.value;
            return match !== undefined && match !== "" ? false : true;
          }))) {
    return "";
  } else {
    return getNameFromStructure(values);
  }
}

function getCurrentAllowedValues(values) {
  return Belt_Array.keepMap(values, (function (value) {
                if (typeof value !== "object") {
                  return ;
                }
                if (value.NAME !== "nameBuildingBlock") {
                  return ;
                }
                var match = value.VAL;
                var value$1 = match.value;
                if (value$1 !== undefined && match.allowedValues.length !== 0) {
                  return value$1;
                }
                
              }));
}

function handleRuleChange(configItem, customStructsAvailability, predefinedValuesAvailability, isUpdatingAdvancedConfigOpt, role, rule, schemaBundle, warningCount, sendActions, updatedConfig) {
  var isUpdatingAdvancedConfig = isUpdatingAdvancedConfigOpt !== undefined ? isUpdatingAdvancedConfigOpt : false;
  var action = GuardRailsConfigUtils.ruleToAnalyticsAction(rule);
  FirebaseUtils.setValidationConfigV2(schemaBundle.schemaId, ValidationConfigV2.encode(updatedConfig), configItem, rule.severity, undefined, sendActions);
  var advancedNameConfigProperties = AnalyticsUtils.getAdvancedNameConfigProperties(ValidationConfigV2.EventsV2.getNameComponents(updatedConfig.events));
  var updatedNameComponents = ValidationConfigV2.EventsV2.getNameComponents(updatedConfig.events);
  var issueReportEventCasing = GuardRailsConfigUtils.nameComponentsToAnalyticsCase(updatedNameComponents);
  var issueReportEventNamingConvention = GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(updatedNameComponents);
  var eventNamingConventionsAvailability = predefinedValuesAvailability === "AvailableDuringTrial" || predefinedValuesAvailability === "Available" ? (
      customStructsAvailability === "Unavailable" ? "PredefinedFrameworkWithPredefinedValues" : (
          customStructsAvailability === "AvailableDuringTrial" || customStructsAvailability === "Available" ? "CustomFramework" : "PredefinedFramework"
        )
    ) : "PredefinedFramework";
  var advancedEventNameStructureComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesAsString, undefined);
  var advancedEventNameStructureComponentConfig = Curry._1(advancedNameConfigProperties.getComponentTypesAsString, undefined);
  var advancedEventNameComponentCount = Curry._1(advancedNameConfigProperties.getComponentCount, undefined);
  var advancedEventNameComponentNames = Curry._1(advancedNameConfigProperties.getComponentNamesIncludingSeparators, undefined);
  var advancedEventNameComponentTypes = Curry._1(advancedNameConfigProperties.getComponentTypesAsArray, undefined);
  var advancedEventNameComponentCasings = Curry._1(advancedNameConfigProperties.getComponentCasings, undefined);
  if (advancedEventNameStructureComponentNames !== undefined && advancedEventNameStructureComponentConfig !== undefined && advancedEventNameComponentCount !== undefined && advancedEventNameComponentNames !== undefined && advancedEventNameComponentTypes !== undefined && advancedEventNameComponentCasings !== undefined && isUpdatingAdvancedConfig) {
    AnalyticsRe.issueReportConfiguredAdvancedEventNamingConvention(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, action, AnalyticsUtils.roleToAnalyticsRole(role), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), issueReportEventNamingConvention);
  }
  var match = rule.value;
  if (typeof match === "object") {
    var variant = match.NAME;
    if (!(variant === "nameComponents" || variant === "blockedValues" || variant === "allowedValues")) {
      var casingAnalytics = GuardRailsConfigUtils.caseToAnalytics(match.VAL);
      return AnalyticsRe.issueReportConfiguredCasing(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, ValidationConfigV2.ConfigItem.toAnalytics(configItem), action, AnalyticsUtils.roleToAnalyticsRole(role), casingAnalytics, schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), GuardRailsConfigUtils.nameComponentsToAnalyticsCase(updatedNameComponents), GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), GuardRailsConfigUtils.nameComponentsToAnalyticsNamingConvention(updatedNameComponents));
    }
    
  }
  return AnalyticsRe.issueReportConfigured(schemaBundle.schemaId, schemaBundle.schemaName, schemaBundle.schemaBillingStatus, schemaBundle.branchId, schemaBundle.branchName, schemaBundle.schemaSubscriptionPlan, schemaBundle.schemaTrialPlan, schemaBundle.authenticationConfig, schemaBundle.schemaSubscriptionPlanSlug, schemaBundle.schemaTrialPlanSlug, schemaBundle.schemaType, issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), warningCount, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig).length, eventNamingConventionsAvailability, advancedEventNameStructureComponentNames, advancedEventNameStructureComponentConfig, advancedEventNameComponentCount, advancedEventNameComponentNames, advancedEventNameComponentTypes, advancedEventNameComponentCasings, issueReportEventNamingConvention, ValidationConfigV2.ConfigItem.toAnalytics(configItem), action, AnalyticsUtils.roleToAnalyticsRole(role), schemaBundle.schemaId, schemaBundle.branchId, schemaBundle.schemaId, AnalyticsUtils.configToConfigsOnAnalytics(updatedConfig), issueReportEventCasing, GuardRailsConfigUtils.caseToAnalytics(Curry._1(ValidationConfigV2.Value.getCase, updatedConfig.properties.case.value)), issueReportEventNamingConvention);
}

function AdvancedNameInput(Props) {
  var currentName = Props.currentName;
  var currentStructure = Props.currentStructure;
  var getItemLink = Props.getItemLink;
  var existingNames = Props.existingNames;
  var createMore = Props.createMore;
  var onClose = Props.onClose;
  var onChange = Props.onChange;
  var eventEdit = Props.eventEdit;
  var role = Props.role;
  var shadowOpt = Props.shadow;
  var shadow = shadowOpt !== undefined ? shadowOpt : false;
  var workspace = WorkspaceContext.use(undefined);
  var schemaBundle = SchemaBundleContext.use(undefined);
  var addToast = Toast.useAddToast(undefined);
  var globalSend = GlobalSendContext.use(undefined);
  var sendActions = SendActionsContext.use(undefined);
  var match = React.useContext(DiscrepancyContext.context);
  var warningCount = match.warningCount;
  var config = match.config;
  var nameComponents = ValidationConfigV2.EventsV2.getNameComponents(config.events);
  var customStructsAvailability = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.CustomStructs.computeAvailability, workspace);
  var predefinedValuesAvailability = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.PredefinedValues.computeAvailability, workspace);
  var hasPredefinedValues = Curry._1(AvoLimits.Guardrails.AdvancedNamingConvention.PredefinedValues.isAvailable, workspace);
  var enforcesUniqueNames = Curry._1(ValidationConfigV2.Rule.isEnforced, config.events.uniqueName);
  var match$1 = CmdKProvider.useDisabled(undefined);
  var setCmdKDisabled = match$1[1];
  React.useEffect((function () {
          Curry._1(setCmdKDisabled, (function (param) {
                  return true;
                }));
          return (function (param) {
                    return Curry._1(setCmdKDisabled, (function (param) {
                                  return false;
                                }));
                  });
        }), []);
  var hasBasicNameCasing = ValidationConfigV2.NameComponent.hasBasicNameCasing(nameComponents);
  var match$2 = React.useState(function () {
        return getValues(currentName, currentStructure, nameComponents);
      });
  var setValues = match$2[1];
  var values = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setClickedSave = match$3[1];
  var clickedSave = match$3[0];
  var match$4 = React.useState(function () {
        return !hasBasicNameCasing;
      });
  var setGuardrailsEnabled = match$4[1];
  var guardrailsEnabled = match$4[0];
  var unableToGetValues = React.useMemo((function () {
          return Belt_Array.keep(values, (function (value) {
                        if (value.NAME === "separator") {
                          return false;
                        }
                        var value$1 = value.VAL.value;
                        if (value$1 !== undefined) {
                          return value$1 === "";
                        } else {
                          return true;
                        }
                      })).length !== 0;
        }), []);
  var nameFromStructure = React.useMemo((function () {
          return getNameFromStructure(values);
        }), [values]);
  var match$5 = React.useState(function () {
        return getSimpleNameFromStructure(values);
      });
  var setSimpleValue = match$5[1];
  var simpleValue = match$5[0];
  var onPaste = function (pastedName) {
    return Curry._1(setValues, (function (param) {
                  return getValues(pastedName, currentStructure, ValidationConfigV2.EventsV2.getNameComponents(config.events));
                }));
  };
  var match$6 = React.useState(function () {
        return Shortid();
      });
  var setSessionIdentifier = match$6[1];
  var sessionIdentifier = match$6[0];
  var onReset = function (param) {
    Curry._1(setValues, (function (param) {
            return getValues(undefined, currentStructure, ValidationConfigV2.EventsV2.getNameComponents(config.events));
          }));
    Curry._1(setSimpleValue, (function (param) {
            
          }));
    return Curry._1(setSessionIdentifier, (function (param) {
                  return Shortid();
                }));
  };
  React.useEffect((function () {
          Curry._1(setSimpleValue, (function (param) {
                  return getSimpleNameFromStructure(values);
                }));
          
        }), [values]);
  var existingNames$1 = React.useMemo((function () {
          if (currentName !== undefined) {
            return Belt_List.keepU(existingNames, (function (name) {
                          return name !== currentName;
                        }));
          } else {
            return existingNames;
          }
        }), [existingNames]);
  var conflictingName = React.useMemo((function () {
          return NameUtils.getConflictingNames(existingNames$1, guardrailsEnabled ? nameFromStructure : Belt_Option.getWithDefault(simpleValue, ""));
        }), [
        existingNames$1,
        nameFromStructure,
        simpleValue,
        guardrailsEnabled
      ]);
  var similarNames = React.useMemo((function () {
          return Belt_List.mapU(Belt_List.sortU(Belt_List.keepU(Belt_List.mapU(existingNames$1, (function (name) {
                                    return [
                                            name,
                                            StringSimilarity.compareTwoStrings(name, guardrailsEnabled ? nameFromStructure : Belt_Option.getWithDefault(simpleValue, ""))
                                          ];
                                  })), (function (param) {
                                return param[1] >= 0.8;
                              })), (function (param, param$1) {
                            var b = param$1[1];
                            var a = param[1];
                            if (a > b) {
                              return 1;
                            } else if (a < b) {
                              return -1;
                            } else {
                              return 0;
                            }
                          })), (function (param) {
                        return param[0];
                      }));
        }), [
        existingNames$1,
        nameFromStructure,
        simpleValue,
        guardrailsEnabled
      ]);
  var allFieldsFilled = React.useMemo((function () {
          if (guardrailsEnabled) {
            return Belt_Array.every(values, (function (value) {
                          if (value.NAME === "separator") {
                            return true;
                          } else {
                            return value.VAL.validation !== "empty";
                          }
                        }));
          } else {
            return Belt_Option.mapWithDefault(simpleValue, false, (function (value) {
                          return value.trim().length > 0;
                        }));
          }
        }), [
        values,
        guardrailsEnabled,
        simpleValue
      ]);
  var blockedValuesUsed = React.useMemo((function () {
          if (guardrailsEnabled) {
            return Belt_Array.keepMap(values, (function (value) {
                          if (value.NAME === "separator") {
                            return ;
                          }
                          var match = value.VAL;
                          var match$1 = match.validation;
                          if (typeof match$1 === "object" && match$1.NAME === "blockedValueConflict") {
                            return [
                                    match.name,
                                    match$1.VAL
                                  ];
                          }
                          
                        }));
          } else {
            return [];
          }
        }), [
        values,
        guardrailsEnabled
      ]);
  var match$7 = React.useState(function () {
        return getCurrentAllowedValues(values);
      });
  var setChosenAllowedValues = match$7[1];
  var chosenAllowedValues = match$7[0];
  React.useEffect((function () {
          var newChosenAllowedValues = getCurrentAllowedValues(values);
          if (!Belt_Array.eq(newChosenAllowedValues, chosenAllowedValues, (function (a, b) {
                    return a === b;
                  }))) {
            Curry._1(setChosenAllowedValues, (function (param) {
                    return newChosenAllowedValues;
                  }));
          }
          
        }), [values]);
  var invalidAllowedValues = React.useMemo((function () {
          if (guardrailsEnabled) {
            return Belt_Array.keepMap(values, (function (value) {
                          if (value.NAME === "separator") {
                            return ;
                          }
                          var buildingBlock = value.VAL;
                          if (buildingBlock.validation === "allowedValuesConflict") {
                            return buildingBlock;
                          }
                          
                        }));
          }
          
        }), [
        guardrailsEnabled,
        chosenAllowedValues
      ]);
  var match$8 = React.useState(function () {
        return false;
      });
  var setInvalidAllowedValuesConfirmed = match$8[1];
  var invalidAllowedValuesConfirmed = match$8[0];
  React.useEffect((function () {
          Curry._1(setInvalidAllowedValuesConfirmed, (function (param) {
                  return false;
                }));
          
        }), [invalidAllowedValues]);
  var onSaveButtonClicked = function (param) {
    var maySave = allFieldsFilled && (conflictingName === undefined || !enforcesUniqueNames) && (
      invalidAllowedValues !== undefined && invalidAllowedValues.length !== 0 ? invalidAllowedValuesConfirmed && hasPredefinedValues : true
    );
    if (!maySave) {
      return Curry._1(setClickedSave, (function (param) {
                    return true;
                  }));
    }
    if (invalidAllowedValues !== undefined && invalidAllowedValues.length !== 0 && invalidAllowedValuesConfirmed) {
      var init = config.events;
      var init$1 = config.events.nameComponents;
      handleRuleChange("ConsistentEventNameCasing", customStructsAvailability, predefinedValuesAvailability, true, role, ValidationConfigV2.EventsV2.getSimplestNameCasing(config.events), schemaBundle, warningCount, sendActions, {
            events: {
              nameComponents: {
                value: {
                  NAME: "nameComponents",
                  VAL: Belt_Array.map(ValidationConfigV2.EventsV2.getNameComponents(config.events), (function (nameComponent) {
                          if (nameComponent.NAME === "separator") {
                            return nameComponent;
                          }
                          var currentBuildingBlock = nameComponent.VAL;
                          if (Curry._1(ValidationConfigV2.Value.getAllowedValues, currentBuildingBlock.allowedValues.value).length === 0) {
                            return nameComponent;
                          }
                          var currentBlockString = currentBuildingBlock.name + ":" + Curry._1(ValidationConfigV2.Value.getAllowedValues, currentBuildingBlock.allowedValues.value).join(";");
                          var newBuildingBlock = Belt_Array.getBy(invalidAllowedValues, (function (param) {
                                  if (param.validation === "valid") {
                                    return false;
                                  }
                                  var value = param.value;
                                  var newBlockString = param.name + ":" + Belt_Array.keepMap(param.allowedValues, (function (allowedValue) {
                                            if (Caml_obj.caml_equal(allowedValue, value)) {
                                              return ;
                                            } else {
                                              return allowedValue;
                                            }
                                          })).join(";");
                                  return currentBlockString === newBlockString;
                                }));
                          if (newBuildingBlock === undefined) {
                            return {
                                    NAME: "nameBuildingBlock",
                                    VAL: currentBuildingBlock
                                  };
                          }
                          var init = currentBuildingBlock.case;
                          var init$1 = currentBuildingBlock.allowedValues;
                          var value = newBuildingBlock.value;
                          var newConvertedBuildingBlock_name = currentBuildingBlock.name;
                          var newConvertedBuildingBlock_case = {
                            value: Belt_Option.mapWithDefault(newBuildingBlock.value, currentBuildingBlock.case.value, (function (value) {
                                    if (Case.of_(value) === Curry._1(ValidationConfigV2.Value.getCase, currentBuildingBlock.case.value)) {
                                      return currentBuildingBlock.case.value;
                                    } else {
                                      return {
                                              NAME: "case",
                                              VAL: "None"
                                            };
                                    }
                                  })),
                            severity: init.severity
                          };
                          var newConvertedBuildingBlock_allowedValues = {
                            value: {
                              NAME: "allowedValues",
                              VAL: value !== undefined ? Belt_Array.concat(Curry._1(ValidationConfigV2.Value.getAllowedValues, currentBuildingBlock.allowedValues.value), [value]) : Curry._1(ValidationConfigV2.Value.getAllowedValues, currentBuildingBlock.allowedValues.value)
                            },
                            severity: init$1.severity
                          };
                          var newConvertedBuildingBlock_blockedValues = currentBuildingBlock.blockedValues;
                          var newConvertedBuildingBlock = {
                            name: newConvertedBuildingBlock_name,
                            case: newConvertedBuildingBlock_case,
                            allowedValues: newConvertedBuildingBlock_allowedValues,
                            blockedValues: newConvertedBuildingBlock_blockedValues
                          };
                          return {
                                  NAME: "nameBuildingBlock",
                                  VAL: newConvertedBuildingBlock
                                };
                        }))
                },
                severity: init$1.severity
              },
              uniqueName: init.uniqueName,
              description: init.description
            },
            properties: config.properties,
            pointOfBlock: config.pointOfBlock
          });
    }
    onSave(ValidationConfigV2.EventsV2.getNameComponents(config.events), guardrailsEnabled, Belt_Option.getWithDefault(simpleValue, ""), schemaBundle.schemaId, values, onChange, (function (param) {
            return Curry._1(addToast, {
                        message: "Failed to save name components",
                        toastType: /* Error */1
                      });
          }), onReset);
    
  };
  var componentContainerRef = React.useRef(null);
  var firstComponentRef = React.useRef(null);
  React.useEffect((function () {
          document.activeElement.blur();
          var el = firstComponentRef.current;
          if (!(el == null)) {
            el.focus();
          }
          
        }), []);
  KeyListener.use(undefined, undefined, undefined, (function (_e) {
          setTimeout((function (param) {
                  var el = componentContainerRef.current;
                  if (el == null) {
                    return ;
                  }
                  if (el.contains(document.activeElement)) {
                    return ;
                  }
                  Curry._1(onClose, undefined);
                  var arg = schemaBundle.schemaId;
                  var arg$1 = schemaBundle.branchId;
                  return AnalyticsUtils.withSchemaBundle((function (param) {
                                var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                                  return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
                                };
                                return function (param) {
                                  var func$1 = Curry._1(func, param);
                                  return function (param) {
                                    var func$2 = Curry._1(func$1, param);
                                    return function (param) {
                                      var func$3 = Curry._1(func$2, param);
                                      return function (param) {
                                        var func$4 = Curry._1(func$3, param);
                                        return function (param) {
                                          var func$5 = Curry._1(func$4, param);
                                          return function (param) {
                                            var func$6 = Curry._1(func$5, param);
                                            return function (param) {
                                              var func$7 = Curry._1(func$6, param);
                                              return function (param) {
                                                var func$8 = Curry._1(func$7, param);
                                                return function (param) {
                                                  var func$9 = Curry._1(func$8, param);
                                                  return function (param) {
                                                    return Curry._4(func$9, param, "DismissByPressingEscape", arg, arg$1);
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              }), schemaBundle);
                }), 100);
          
        }), "Escape");
  var tmp;
  if (guardrailsEnabled) {
    var indexWithAutoFocus = Belt_Array.getIndexBy(values, (function (value) {
            return value.NAME !== "separator";
          }));
    tmp = Belt_Array.mapWithIndex(values, (function (index, value) {
            if (value.NAME === "separator") {
              return React.createElement("div", {
                          key: String(index),
                          className: Curry._1(Css.style, {
                                hd: Css.unsafe("whiteSpace", "preserve"),
                                tl: /* [] */0
                              })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Medium",
                              weight: "Semi",
                              color: Styles.Color.light08,
                              children: React.createElement("span", {
                                    className: Curry._1(Css.style, {
                                          hd: Css.letterSpacing(Css.px(1)),
                                          tl: /* [] */0
                                        })
                                  }, value.VAL)
                            }));
            }
            var match = value.VAL;
            var blockedValues = match.blockedValues;
            var allowedValues = match.allowedValues;
            var $$case = match.case;
            var value$1 = match.value;
            var tmp = {
              allowedValues: allowedValues,
              autoFocus: Belt_Option.mapWithDefault(indexWithAutoFocus, false, (function (i) {
                      return i === index;
                    })),
              case: $$case,
              eventEdit: eventEdit,
              onEnter: onSaveButtonClicked,
              onEscape: (function (param) {
                  Curry._1(onClose, undefined);
                  var arg = schemaBundle.schemaId;
                  var arg$1 = schemaBundle.branchId;
                  return AnalyticsUtils.withSchemaBundle((function (param) {
                                var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                                  return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
                                };
                                return function (param) {
                                  var func$1 = Curry._1(func, param);
                                  return function (param) {
                                    var func$2 = Curry._1(func$1, param);
                                    return function (param) {
                                      var func$3 = Curry._1(func$2, param);
                                      return function (param) {
                                        var func$4 = Curry._1(func$3, param);
                                        return function (param) {
                                          var func$5 = Curry._1(func$4, param);
                                          return function (param) {
                                            var func$6 = Curry._1(func$5, param);
                                            return function (param) {
                                              var func$7 = Curry._1(func$6, param);
                                              return function (param) {
                                                var func$8 = Curry._1(func$7, param);
                                                return function (param) {
                                                  var func$9 = Curry._1(func$8, param);
                                                  return function (param) {
                                                    return Curry._4(func$9, param, "DismissByPressingEscape", arg, arg$1);
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              }), schemaBundle);
                }),
              name: match.name,
              onChange: (function (newValue) {
                  return Curry._1(setValues, (function (currentValues) {
                                return Belt_Array.mapWithIndex(currentValues, (function (i, value) {
                                              if (index !== i) {
                                                return value;
                                              }
                                              if (value.NAME === "separator") {
                                                return value;
                                              }
                                              var buildingBlock = value.VAL;
                                              return {
                                                      NAME: "nameBuildingBlock",
                                                      VAL: {
                                                        name: buildingBlock.name,
                                                        value: newValue,
                                                        validation: getInputValidation(newValue, $$case, allowedValues, blockedValues),
                                                        case: buildingBlock.case,
                                                        allowedValues: buildingBlock.allowedValues,
                                                        blockedValues: buildingBlock.blockedValues
                                                      }
                                                    };
                                            }));
                              }));
                }),
              role: role,
              validation: match.validation,
              value: value$1,
              hasBlockedValue: value$1 !== undefined ? isBlockedValue(value$1, blockedValues) : false,
              key: String(index) + sessionIdentifier
            };
            var tmp$1 = Belt_Option.mapWithDefault(indexWithAutoFocus, undefined, (function (i) {
                    if (i === index) {
                      return firstComponentRef;
                    }
                    
                  }));
            if (tmp$1 !== undefined) {
              tmp.inputRef = Caml_option.valFromOption(tmp$1);
            }
            var tmp$2 = Caml_obj.caml_equal(index, indexWithAutoFocus) ? onPaste : undefined;
            if (tmp$2 !== undefined) {
              tmp.onPaste = Caml_option.valFromOption(tmp$2);
            }
            return React.createElement(AdvancedNameInput$Component, tmp);
          }));
  } else {
    tmp = React.createElement(GuardRailsConfigComponents.AutoGrowingInput.make, {
          containerClassName: Curry._1(Css.style, {
                hd: Css.display("inlineBlock"),
                tl: {
                  hd: Css.margin(Css.px(-6)),
                  tl: {
                    hd: Css.marginTop(Css.px(-5)),
                    tl: {
                      hd: Css.padding(Css.px(6)),
                      tl: {
                        hd: Css.paddingTop(Css.px(7)),
                        tl: {
                          hd: Css.focusWithin({
                                hd: Css.backgroundColor(Styles.Color.white),
                                tl: {
                                  hd: Css.outline(Css.px(0), "solid", Styles.Color.light07),
                                  tl: /* [] */0
                                }
                              }),
                          tl: {
                            hd: Css.outline("zero", "solid", "transparent"),
                            tl: {
                              hd: Css.paddingBottom(Css.pxFloat(24.5)),
                              tl: /* [] */0
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }),
          className: Curry._1(Css.style, {
                hd: Css.padding("zero"),
                tl: {
                  hd: Css.margin("zero"),
                  tl: {
                    hd: Css.left(Css.px(4)),
                    tl: {
                      hd: Css.top(Css.pxFloat(6.5)),
                      tl: {
                        hd: Css.fontSize(Css.px(14)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }),
          height: 24,
          inputRef: firstComponentRef,
          onChange: (function (maybeValue) {
              return Belt_Option.forEach(maybeValue, (function (value) {
                            return Curry._1(setSimpleValue, (function (param) {
                                          return value;
                                        }));
                          }));
            }),
          onEnter: onSaveButtonClicked,
          onEscape: (function (param) {
              Curry._1(onClose, undefined);
              var arg = schemaBundle.schemaId;
              var arg$1 = schemaBundle.branchId;
              return AnalyticsUtils.withSchemaBundle((function (param) {
                            var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                              return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
                            };
                            return function (param) {
                              var func$1 = Curry._1(func, param);
                              return function (param) {
                                var func$2 = Curry._1(func$1, param);
                                return function (param) {
                                  var func$3 = Curry._1(func$2, param);
                                  return function (param) {
                                    var func$4 = Curry._1(func$3, param);
                                    return function (param) {
                                      var func$5 = Curry._1(func$4, param);
                                      return function (param) {
                                        var func$6 = Curry._1(func$5, param);
                                        return function (param) {
                                          var func$7 = Curry._1(func$6, param);
                                          return function (param) {
                                            var func$8 = Curry._1(func$7, param);
                                            return function (param) {
                                              var func$9 = Curry._1(func$8, param);
                                              return function (param) {
                                                return Curry._4(func$9, param, "DismissByPressingEscape", arg, arg$1);
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              };
                            };
                          }), schemaBundle);
            }),
          placeholder: "Event name",
          value: Belt_Option.getWithDefault(simpleValue, ""),
          autoFocus: true,
          key: sessionIdentifier
        });
  }
  var tmp$1;
  if (hasBasicNameCasing) {
    tmp$1 = null;
  } else {
    var match$9 = config.events.nameComponents.severity;
    tmp$1 = React.createElement(SimpleTooltip.WithComponent.make, {
          maxWidth: 200,
          tooltip: match$9 === "enforce" ? React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      children: null
                    }, "Names must follow ", React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.textDecoration("underline"),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              Curry._1(globalSend, {
                                    TAG: /* OpenSlideOver */6,
                                    _0: {
                                      NAME: "DiscrepanciesConfig",
                                      VAL: undefined
                                    }
                                  });
                              return Curry._1(onClose, undefined);
                            })
                        }, "workspace rules"), " for structure and casing."), role !== "Admin" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                            height: 16
                          }), React.createElement($$Text.make, {
                            size: "Small",
                            children: "Guardrails can only be disabled by admin users."
                          })) : null) : React.createElement(React.Fragment, undefined, React.createElement($$Text.make, {
                      element: "Span",
                      size: "Small",
                      children: null
                    }, "Names should follow ", React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.textDecoration("underline"),
                                tl: {
                                  hd: Css.cursor("pointer"),
                                  tl: /* [] */0
                                }
                              }),
                          onClick: (function (param) {
                              Curry._1(globalSend, {
                                    TAG: /* OpenSlideOver */6,
                                    _0: {
                                      NAME: "DiscrepanciesConfig",
                                      VAL: undefined
                                    }
                                  });
                              return Curry._1(onClose, undefined);
                            })
                        }, "workspace rules"), " for structure and casing.")),
          children: React.createElement("div", {
                className: Curry._1(Css.style, {
                      hd: Css.display("flex"),
                      tl: {
                        hd: Css.alignItems("flexEnd"),
                        tl: {
                          hd: Css.gap(Css.px(2)),
                          tl: /* [] */0
                        }
                      }
                    })
              }, React.createElement($$Text.make, {
                    size: "Tiny",
                    weight: "Semi",
                    color: Styles.Color.light10,
                    children: "Guardrails"
                  }), React.createElement(Icon.make, {
                    type_: "information",
                    size: "small",
                    color: Styles.Color.light10
                  }))
        });
  }
  var match$10 = config.events.nameComponents.severity;
  var tmp$2;
  if (conflictingName !== undefined) {
    var path = Curry._1(getItemLink, conflictingName);
    tmp$2 = React.createElement("span", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.flexDirection("row"),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.gap(Css.px(8)),
                      tl: {
                        hd: Css.height(Css.px(17)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              })
        }, React.createElement(Icon.make, {
              type_: enforcesUniqueNames ? "close" : "warning",
              size: "small",
              color: clickedSave ? (
                  enforcesUniqueNames ? Styles.Color.red : Styles.Color.orange
                ) : (
                  enforcesUniqueNames ? Styles.Color.light07 : Styles.Color.orange
                )
            }), React.createElement($$Text.make, {
              element: "Span",
              size: "Small",
              color: Styles.Color.light10,
              children: "\"" + conflictingName + "\" already exists"
            }), path !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(TextButton.make, {
                    onClick: (function (param) {
                        Router.push(undefined, path);
                        return Curry._1(onClose, undefined);
                      }),
                    children: React.createElement("span", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(2)),
                                    tl: {
                                      hd: Css.color(Styles.Color.darkBlue),
                                      tl: {
                                        hd: Icon.color(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Css.hover({
                                                hd: Css.color(Styles.Color.deepBlue),
                                                tl: {
                                                  hd: Icon.color(Styles.Color.deepBlue),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              })
                        }, React.createElement($$Text.make, {
                              element: "Span",
                              size: "Small",
                              children: "Go to"
                            }), React.createElement(Icon.make, {
                              type_: "arrowRight",
                              size: {
                                NAME: "int",
                                VAL: 12
                              }
                            }))
                  })) : null);
  } else {
    tmp$2 = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.alignItems("center"),
                  tl: {
                    hd: Css.gap(Css.px(8)),
                    tl: /* [] */0
                  }
                }
              })
        }, React.createElement(Icon.make, {
              type_: conflictingName === undefined ? "checkmark" : "close",
              size: "small",
              color: conflictingName !== undefined ? (
                  clickedSave ? Styles.Color.red : Styles.Color.light07
                ) : Styles.Color.green
            }), React.createElement($$Text.make, {
              size: "Small",
              weight: allFieldsFilled ? "Semi" : "Regular",
              color: Styles.Color.light10,
              children: "Event has a unique name"
            }));
  }
  var tmp$3;
  var exit = 0;
  var similarName;
  if (similarNames) {
    var similarName$1 = similarNames.hd;
    similarName = similarName$1;
    exit = 1;
  } else {
    tmp$3 = React.createElement("span", undefined);
  }
  if (exit === 1) {
    if (conflictingName === undefined) {
      var path$1 = Curry._1(getItemLink, similarName);
      tmp$3 = React.createElement("span", {
            className: Curry._1(Css.style, {
                  hd: Css.display("flex"),
                  tl: {
                    hd: Css.marginTop(Css.px(16)),
                    tl: {
                      hd: Css.alignItems("baseline"),
                      tl: {
                        hd: Css.gap(Css.px(4)),
                        tl: /* [] */0
                      }
                    }
                  }
                })
          }, React.createElement($$Text.make, {
                element: "Span",
                size: "Small",
                color: Styles.Color.light11,
                children: null
              }, "Similar to ", React.createElement($$Text.make, {
                    element: "Span",
                    weight: "Semi",
                    children: "\"" + similarName + "\""
                  })), path$1 !== undefined ? React.createElement("span", {
                  className: Curry._1(Css.style, {
                        hd: Css.paddingLeft(Css.px(2)),
                        tl: /* [] */0
                      })
                }, React.createElement(TextButton.make, {
                      onClick: (function (param) {
                          Router.push(undefined, path$1);
                          return Curry._1(onClose, undefined);
                        }),
                      children: React.createElement("span", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.alignItems("center"),
                                    tl: {
                                      hd: Css.gap(Css.px(2)),
                                      tl: {
                                        hd: Css.color(Styles.Color.darkBlue),
                                        tl: {
                                          hd: Icon.color(Styles.Color.darkBlue),
                                          tl: {
                                            hd: Css.hover({
                                                  hd: Css.color(Styles.Color.deepBlue),
                                                  tl: {
                                                    hd: Icon.color(Styles.Color.deepBlue),
                                                    tl: /* [] */0
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  }
                                })
                          }, React.createElement($$Text.make, {
                                element: "Span",
                                size: "Small",
                                children: "Go to"
                              }), React.createElement(Icon.make, {
                                type_: "arrowRight",
                                size: {
                                  NAME: "int",
                                  VAL: 12
                                }
                              }))
                    })) : null);
    } else {
      tmp$3 = React.createElement("span", undefined);
    }
  }
  return React.createElement("div", {
              className: Curry._1(Css.style, {
                    hd: Css.position("fixed"),
                    tl: {
                      hd: Css.top(Css.zero),
                      tl: {
                        hd: Css.bottom(Css.zero),
                        tl: {
                          hd: Css.left(Css.zero),
                          tl: {
                            hd: Css.right(Css.zero),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.paddingTop(Css.vh(35.0)),
                                tl: {
                                  hd: Css.alignItems("flexStart"),
                                  tl: {
                                    hd: Css.justifyContent("center"),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }),
              onClick: (function (e) {
                  e.stopPropagation();
                  Curry._1(onClose, undefined);
                  var arg = schemaBundle.schemaId;
                  var arg$1 = schemaBundle.branchId;
                  return AnalyticsUtils.withSchemaBundle((function (param) {
                                var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                                  return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
                                };
                                return function (param) {
                                  var func$1 = Curry._1(func, param);
                                  return function (param) {
                                    var func$2 = Curry._1(func$1, param);
                                    return function (param) {
                                      var func$3 = Curry._1(func$2, param);
                                      return function (param) {
                                        var func$4 = Curry._1(func$3, param);
                                        return function (param) {
                                          var func$5 = Curry._1(func$4, param);
                                          return function (param) {
                                            var func$6 = Curry._1(func$5, param);
                                            return function (param) {
                                              var func$7 = Curry._1(func$6, param);
                                              return function (param) {
                                                var func$8 = Curry._1(func$7, param);
                                                return function (param) {
                                                  var func$9 = Curry._1(func$8, param);
                                                  return function (param) {
                                                    return Curry._4(func$9, param, "DismissByClickingOutside", arg, arg$1);
                                                  };
                                                };
                                              };
                                            };
                                          };
                                        };
                                      };
                                    };
                                  };
                                };
                              }), schemaBundle);
                })
            }, React.createElement("div", {
                  className: Curry._1(Css.style, {
                        hd: Css.width(Css.px(600)),
                        tl: {
                          hd: Css.backgroundColor(Styles.Color.white),
                          tl: {
                            hd: Css.borderRadius(Styles.Border.radius),
                            tl: {
                              hd: Css.display("flex"),
                              tl: {
                                hd: Css.flexDirection("column"),
                                tl: {
                                  hd: Css.paddingTop(Css.px(26)),
                                  tl: {
                                    hd: shadow ? Css.boxShadow(Styles.Shadow.standard) : Styles.emptyStyle,
                                    tl: {
                                      hd: Css.overflow("hidden"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }),
                  onClick: (function (prim) {
                      prim.stopPropagation();
                      
                    })
                }, currentName !== undefined && unableToGetValues ? React.createElement(AdvancedNameInput$Section, {
                        h: 16,
                        children: React.createElement("div", {
                              className: Curry._1(Css.style, {
                                    hd: Css.backgroundColor(Styles.Color.lightOrange),
                                    tl: {
                                      hd: Css.padding(Css.px(16)),
                                      tl: {
                                        hd: Css.marginBottom(Css.px(32)),
                                        tl: {
                                          hd: Css.borderRadius(Styles.Border.radius),
                                          tl: {
                                            hd: Styles.prettyWrap,
                                            tl: /* [] */0
                                          }
                                        }
                                      }
                                    }
                                  })
                            }, React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.gap(Css.px(8)),
                                          tl: /* [] */0
                                        }
                                      })
                                }, React.createElement("div", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.marginTop(Css.px(2)),
                                            tl: /* [] */0
                                          })
                                    }, React.createElement(Icon.make, {
                                          type_: "information",
                                          size: "small",
                                          color: Styles.Color.darkOrange
                                        })), React.createElement("div", undefined, React.createElement($$Text.make, {
                                          size: "Medium",
                                          color: Styles.Color.deepOrange,
                                          children: null
                                        }, "Unable to get component values for ", React.createElement("em", undefined, "\"" + currentName + "\""), ". Please fill in the values manually."))))
                      }) : null, React.createElement(AdvancedNameInput$Section, {
                      v: 16,
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.justifyContent("spaceBetween"),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("span", {
                                ref: componentContainerRef,
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.flexDirection("row"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: /* [] */0
                                        }
                                      }
                                    })
                              }, tmp), React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.marginTop(Css.px(-24)),
                                      tl: {
                                        hd: Css.display("flex"),
                                        tl: {
                                          hd: Css.alignItems("center"),
                                          tl: {
                                            hd: Css.gap(Css.px(8)),
                                            tl: {
                                              hd: Css.height("fitContent"),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, tmp$1, React.createElement(Switch.make, {
                                    value: guardrailsEnabled,
                                    onChange: (function (param) {
                                        return Curry._1(setGuardrailsEnabled, (function (guardrailsEnabled) {
                                                      return !guardrailsEnabled;
                                                    }));
                                      }),
                                    color: "avoPink",
                                    size: "Tiny",
                                    disabled: match$10 === "enforce" ? role !== "Admin" : false,
                                    tabIndex: 10
                                  })))
                    }), React.createElement(AdvancedNameInput$Separator, {}), React.createElement(AdvancedNameInput$Section, {
                      v: 16,
                      backgroundColor: Styles.Color.light02,
                      children: null
                    }, React.createElement("div", {
                          className: Curry._1(Css.style, {
                                hd: Css.display("flex"),
                                tl: {
                                  hd: Css.alignItems("center"),
                                  tl: {
                                    hd: Css.gap(Css.px(8)),
                                    tl: {
                                      hd: guardrailsEnabled ? Styles.emptyStyle : Css.textDecoration("lineThrough"),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              })
                        }, React.createElement(Icon.make, {
                              type_: allFieldsFilled && guardrailsEnabled ? "checkmark" : "close",
                              size: "small",
                              color: allFieldsFilled ? (
                                  guardrailsEnabled ? Styles.Color.green : (
                                      guardrailsEnabled ? Styles.Color.red : Styles.Color.light07
                                    )
                                ) : (
                                  clickedSave && guardrailsEnabled ? Styles.Color.red : Styles.Color.light07
                                )
                            }), React.createElement($$Text.make, {
                              size: "Small",
                              weight: allFieldsFilled && guardrailsEnabled ? "Semi" : "Regular",
                              color: Styles.Color.light10,
                              children: "All fields are filled"
                            })), tmp$2, blockedValuesUsed.length !== 0 ? Belt_Array.map(blockedValuesUsed, (function (blockedValuesUsedForComponent) {
                              return React.createElement("div", {
                                          key: blockedValuesUsedForComponent[0],
                                          className: Curry._1(Css.style, {
                                                hd: Css.display("flex"),
                                                tl: {
                                                  hd: Css.alignItems("center"),
                                                  tl: {
                                                    hd: Css.gap(Css.px(8)),
                                                    tl: /* [] */0
                                                  }
                                                }
                                              })
                                        }, React.createElement(Icon.make, {
                                              type_: "warning",
                                              size: "small",
                                              color: Styles.Color.orange
                                            }), React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light10,
                                              children: "The " + AvoUtils.plural(undefined, false, blockedValuesUsedForComponent[1].length, "word") + " " + StringExt.joinToSentence(Belt_Array.map(blockedValuesUsedForComponent[1], (function (w) {
                                                          return "\"" + w + "\"";
                                                        }))) + " may not be used for the \"" + blockedValuesUsedForComponent[0] + "\" component"
                                            }));
                            })) : null, invalidAllowedValues !== undefined && Caml_obj.caml_notequal(invalidAllowedValues, []) ? React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.marginTop(Css.px(16)),
                                  tl: /* [] */0
                                })
                          }, Belt_Array.map(invalidAllowedValues, (function (param) {
                                  var value = param.value;
                                  var name = param.name;
                                  return React.createElement($$Text.make, {
                                              size: "Small",
                                              color: Styles.Color.light11,
                                              children: "\"" + Belt_Option.getWithDefault(value, "") + "\" will be added as an allowed " + name + " value",
                                              key: name + Belt_Option.getWithDefault(value, "")
                                            });
                                })), React.createElement(Spacer.make, {
                                height: 8
                              }), role === "Admin" ? React.createElement(Mantine.Checkbox.make, {
                                  checked: invalidAllowedValuesConfirmed,
                                  color: "magenta100",
                                  label: "Confirm adding values when event is " + (
                                    eventEdit === "Update" ? "updated" : "created"
                                  ),
                                  onChange: (function (param) {
                                      return Curry._1(setInvalidAllowedValuesConfirmed, (function (currentValue) {
                                                    return !currentValue;
                                                  }));
                                    }),
                                  size: "xs"
                                }) : React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: "Contact an admin to add allowed values"
                                })) : null, tmp$3), React.createElement(AdvancedNameInput$Separator, {}), React.createElement(AdvancedNameInput$Section, {
                      v: 16,
                      backgroundColor: Styles.Color.light02,
                      children: React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.display("flex"),
                                  tl: {
                                    hd: Css.gap(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.style, {
                                      hd: Css.display("flex"),
                                      tl: {
                                        hd: Css.gap(Css.px(12)),
                                        tl: {
                                          hd: Css.flexGrow(1.0),
                                          tl: {
                                            hd: Css.alignItems("center"),
                                            tl: {
                                              hd: Css.marginLeft(Css.px(-16)),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    })
                              }, React.createElement(Button.make, {
                                    label: "Cancel",
                                    onClick: (function (param) {
                                        Curry._1(onClose, undefined);
                                        var arg = schemaBundle.schemaId;
                                        var arg$1 = schemaBundle.branchId;
                                        return AnalyticsUtils.withSchemaBundle((function (param) {
                                                      var func = function (param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13) {
                                                        return AnalyticsRe.createEventModalInteraction(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8, param$9, param$10, param$11, param$12, param$13);
                                                      };
                                                      return function (param) {
                                                        var func$1 = Curry._1(func, param);
                                                        return function (param) {
                                                          var func$2 = Curry._1(func$1, param);
                                                          return function (param) {
                                                            var func$3 = Curry._1(func$2, param);
                                                            return function (param) {
                                                              var func$4 = Curry._1(func$3, param);
                                                              return function (param) {
                                                                var func$5 = Curry._1(func$4, param);
                                                                return function (param) {
                                                                  var func$6 = Curry._1(func$5, param);
                                                                  return function (param) {
                                                                    var func$7 = Curry._1(func$6, param);
                                                                    return function (param) {
                                                                      var func$8 = Curry._1(func$7, param);
                                                                      return function (param) {
                                                                        var func$9 = Curry._1(func$8, param);
                                                                        return function (param) {
                                                                          return Curry._4(func$9, param, "Cancel", arg, arg$1);
                                                                        };
                                                                      };
                                                                    };
                                                                  };
                                                                };
                                                              };
                                                            };
                                                          };
                                                        };
                                                      };
                                                    }), schemaBundle);
                                      }),
                                    style: "ghost"
                                  }), React.createElement(Spacer.make, {
                                    width: 8,
                                    grow: 1.0
                                  }), createMore !== undefined ? React.createElement("span", {
                                      className: Curry._1(Css.style, {
                                            hd: Css.display("flex"),
                                            tl: {
                                              hd: Css.gap(Css.px(8)),
                                              tl: {
                                                hd: Css.alignItems("center"),
                                                tl: /* [] */0
                                              }
                                            }
                                          }),
                                      onClick: createMore[1]
                                    }, React.createElement($$Text.make, {
                                          size: "Small",
                                          color: Styles.Color.light10,
                                          children: "Create more"
                                        }), React.createElement("span", {
                                          className: Curry._1(Css.style, {
                                                hd: Css.padding2(Css.px(2), Css.px(4)),
                                                tl: {
                                                  hd: Css.backgroundColor(Styles.Color.light04),
                                                  tl: {
                                                    hd: Css.borderRadius(Css.px(3)),
                                                    tl: {
                                                      hd: Css.border(Css.pxFloat(0.5), Css.solid, Styles.Color.light08),
                                                      tl: {
                                                        hd: Css.fontSize(Css.px(10)),
                                                        tl: {
                                                          hd: Css.fontFamily(Styles.FontFamily.polar),
                                                          tl: {
                                                            hd: Css.color(Styles.Color.light11),
                                                            tl: {
                                                              hd: Css.marginTop(Css.px(-2)),
                                                              tl: /* [] */0
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              })
                                        }, "^m"), React.createElement(Switch.make, {
                                          value: createMore[0],
                                          onChange: (function (param) {
                                              
                                            }),
                                          color: "avoPink",
                                          size: "Tiny"
                                        })) : null, React.createElement(Button.make, {
                                    disabled: blockedValuesUsed.length !== 0,
                                    label: eventEdit === "Create" ? "Create event" : "Update event",
                                    onClick: (function (param) {
                                        return onSaveButtonClicked(undefined);
                                      }),
                                    tabIndex: 0
                                  })))
                    })));
}

var make = AdvancedNameInput;

export {
  make ,
  
}
/* targetContainerStyles Not a pure module */
