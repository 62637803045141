// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.mjs";
import * as Icon from "./Icon.mjs";
import * as $$Text from "./Text.mjs";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Hooks from "./Hooks.mjs";
import * as React from "react";
import * as Spacer from "./Spacer.mjs";
import * as Styles from "./styles.mjs";
import * as Mantine from "./Mantine.mjs";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as IconErase from "./IconErase.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Fuzzaldrin from "fuzzaldrin";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as KeyListener from "./KeyListener.mjs";
import * as Core from "@mantine/core";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.mjs";

var targetRoot = Curry._1(Css.style, {
      hd: Css.position("relative"),
      tl: /* [] */0
    });

var targetContainer = Curry._1(Css.style, {
      hd: Css.cursor("pointer"),
      tl: {
        hd: Css.borderRadius(Css.px(5)),
        tl: {
          hd: Css.transition({
                NAME: "ms",
                VAL: Styles.Duration.$$short
              }, undefined, undefined, "all"),
          tl: {
            hd: Css.backgroundColor(Styles.Color.white),
            tl: {
              hd: Css.overflow("hidden"),
              tl: /* [] */0
            }
          }
        }
      }
    });

var targetContent = Curry._1(Css.style, {
      hd: Css.padding2(Css.px(4), Css.px(8)),
      tl: {
        hd: Css.color(Styles.Color.white),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.gap(Css.px(4)),
            tl: /* [] */0
          }
        }
      }
    });

var dropdown_0 = Css.minWidth(Css.px(200));

var dropdown_1 = {
  hd: Css.paddingTop(Css.px(8)),
  tl: {
    hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, Css.px(4), Css.px(24), undefined, undefined, Css.rgba(0, 0, 0, {
                  NAME: "num",
                  VAL: 0.15
                }))),
    tl: {
      hd: Css.transform(Css.translateY(Css.px(-6))),
      tl: {
        hd: Css.maxHeight(Css.vh(40.0)),
        tl: {
          hd: Css.overflow("auto"),
          tl: {
            hd: Css.borderColor(Styles.Color.light04),
            tl: /* [] */0
          }
        }
      }
    }
  }
};

var dropdown = {
  hd: dropdown_0,
  tl: dropdown_1
};

var dropdownItemContainer_0 = Css.padding2(Css.px(8), Css.px(16));

var dropdownItemContainer_1 = {
  hd: Css.position("relative"),
  tl: {
    hd: Css.borderRadius("zero"),
    tl: /* [] */0
  }
};

var dropdownItemContainer = {
  hd: dropdownItemContainer_0,
  tl: dropdownItemContainer_1
};

var dropdownDoneContainer_0 = Css.position("sticky");

var dropdownDoneContainer_1 = {
  hd: Css.left(Css.px(0)),
  tl: {
    hd: Css.bottom(Css.px(0)),
    tl: {
      hd: Css.width(Css.pct(100.0)),
      tl: {
        hd: Css.borderRadius("zero"),
        tl: {
          hd: Css.borderTop(Css.px(1), Css.solid, Styles.Color.light07),
          tl: {
            hd: Css.backgroundColor(Styles.Color.lightBlue),
            tl: {
              hd: Css.color(Styles.Color.darkBlue),
              tl: {
                hd: Icon.color(Styles.Color.darkBlue),
                tl: {
                  hd: Css.transition({
                        NAME: "ms",
                        VAL: Styles.Duration.$$short
                      }, undefined, undefined, "all"),
                  tl: {
                    hd: Css.hover({
                          hd: Styles.backgroundColorGuarantee(Styles.Color.setAlpha(Styles.Color.paleBlue, 0.3)),
                          tl: {
                            hd: Css.color(Styles.Color.deepBlue),
                            tl: {
                              hd: Icon.color(Styles.Color.deepBlue),
                              tl: /* [] */0
                            }
                          }
                        }),
                    tl: {
                      hd: Css.padding(Css.px(16)),
                      tl: {
                        hd: Css.paddingLeft(Css.px(20)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

var dropdownDoneContainer = {
  hd: dropdownDoneContainer_0,
  tl: dropdownDoneContainer_1
};

var dropdownDoneButton = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.flexDirection("row"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.gap(Css.px(8)),
            tl: /* [] */0
          }
        }
      }
    });

var dropdownItem = Curry._1(Css.style, {
      hd: Css.display("flex"),
      tl: {
        hd: Css.alignItems("center"),
        tl: {
          hd: Css.gap(Css.px(4)),
          tl: /* [] */0
        }
      }
    });

function searchContainer(obtainedWidth) {
  return Curry._1(Css.style, {
              hd: Css.padding2(Css.px(0), Css.px(16)),
              tl: {
                hd: Css.display("flex"),
                tl: {
                  hd: Css.gap(Css.px(8)),
                  tl: {
                    hd: Css.alignItems("center"),
                    tl: {
                      hd: Css.backgroundColor(Styles.Color.white),
                      tl: {
                        hd: obtainedWidth !== undefined ? Css.width(Css.px(obtainedWidth)) : Css.width(Css.pct(100.0)),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

var searchInput = Curry._1(Css.style, {
      hd: Css.width(Css.pct(100)),
      tl: {
        hd: Css.padding(Css.px(16)),
        tl: {
          hd: Css.paddingLeft(Css.px(0)),
          tl: {
            hd: Css.fontSize(Css.px(12)),
            tl: {
              hd: Css.fontWeight({
                    NAME: "num",
                    VAL: 500
                  }),
              tl: {
                hd: Css.borderWidth(Css.px(0)),
                tl: {
                  hd: Css.outlineStyle("none"),
                  tl: {
                    hd: Css.outlineWidth(Css.px(0)),
                    tl: {
                      hd: Css.color(Styles.Color.light10),
                      tl: {
                        hd: Css.placeholder({
                              hd: Css.color(Styles.Color.light08),
                              tl: /* [] */0
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

function eraseButton(disabled) {
  return Curry._1(Css.style, {
              hd: Css.padding(Css.px(0)),
              tl: {
                hd: Css.marginTop(Css.px(0)),
                tl: {
                  hd: Css.marginBottom(Css.px(0)),
                  tl: {
                    hd: Css.marginLeft(Css.px(0)),
                    tl: {
                      hd: Css.margin(Css.px(0)),
                      tl: {
                        hd: Css.borderWidth(Css.px(0)),
                        tl: {
                          hd: Css.borderRadius(Css.px(2)),
                          tl: {
                            hd: Css.background("transparent"),
                            tl: {
                              hd: Css.transition({
                                    NAME: "ms",
                                    VAL: Styles.Duration.$$default
                                  }, undefined, undefined, "opacity"),
                              tl: {
                                hd: Css.opacity(disabled ? 0 : 1),
                                tl: {
                                  hd: Css.unsafe("appearance", "none"),
                                  tl: {
                                    hd: Css.outlineStyle("none"),
                                    tl: {
                                      hd: Css.cursor("pointer"),
                                      tl: {
                                        hd: Css.active({
                                              hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, undefined, undefined, "transparent")),
                                              tl: /* [] */0
                                            }),
                                        tl: {
                                          hd: Css.focus({
                                                hd: Css.outlineStyle("none"),
                                                tl: {
                                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(undefined, undefined, undefined, Css.px(2), undefined, Styles.Color.darkBlue)),
                                                  tl: /* [] */0
                                                }
                                              }),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            });
}

var Style = {
  targetRoot: targetRoot,
  targetContainer: targetContainer,
  targetContent: targetContent,
  dropdown: dropdown,
  dropdownItemContainer: dropdownItemContainer,
  dropdownDoneContainer: dropdownDoneContainer,
  dropdownDoneButton: dropdownDoneButton,
  dropdownItem: dropdownItem,
  searchContainer: searchContainer,
  searchInput: searchInput,
  eraseButton: eraseButton
};

var Item = {};

function SearchSelect(Props) {
  var confirmSelectionOpt = Props.confirmSelection;
  var errorMessage = Props.errorMessage;
  var items = Props.items;
  var maxItemsBeforeOfferingSearchOpt = Props.maxItemsBeforeOfferingSearch;
  var arbitraryChoiceOpt = Props.arbitraryChoice;
  var multiSelectOpt = Props.multiSelect;
  var onEnter = Props.onEnter;
  var onEscape = Props.onEscape;
  var onChange = Props.onChange;
  var onClose = Props.onClose;
  var rightSection = Props.rightSection;
  var searchInputResetIconOpt = Props.searchInputResetIcon;
  var targetContainerStylesOpt = Props.targetContainerStyles;
  var targetContentStylesOpt = Props.targetContentStyles;
  var targetOpenedStylesOpt = Props.targetOpenedStyles;
  var dropdownContainerStylesOpt = Props.dropdownContainerStyles;
  var dropdownItemStylesOpt = Props.dropdownItemStyles;
  var children = Props.children;
  var confirmSelection = confirmSelectionOpt !== undefined ? confirmSelectionOpt : false;
  var maxItemsBeforeOfferingSearch = maxItemsBeforeOfferingSearchOpt !== undefined ? maxItemsBeforeOfferingSearchOpt : 15;
  var arbitraryChoice = arbitraryChoiceOpt !== undefined ? arbitraryChoiceOpt : "off";
  var multiSelect = multiSelectOpt !== undefined ? multiSelectOpt : false;
  var searchInputResetIcon = searchInputResetIconOpt !== undefined ? Caml_option.valFromOption(searchInputResetIconOpt) : React.createElement(IconErase.make, {
          size: 18,
          color: Styles.Color.light07,
          hoverColor: Styles.Color.red
        });
  var targetContainerStyles = targetContainerStylesOpt !== undefined ? targetContainerStylesOpt : Curry._1(Css.style, /* [] */0);
  var targetContentStyles = targetContentStylesOpt !== undefined ? targetContentStylesOpt : Curry._1(Css.style, /* [] */0);
  var targetOpenedStyles = targetOpenedStylesOpt !== undefined ? targetOpenedStylesOpt : Curry._1(Css.style, /* [] */0);
  var dropdownContainerStyles = dropdownContainerStylesOpt !== undefined ? dropdownContainerStylesOpt : /* [] */0;
  var dropdownItemStyles = dropdownItemStylesOpt !== undefined ? dropdownItemStylesOpt : (function (param) {
        return Curry._1(Css.style, /* [] */0);
      });
  var searchContainerRef = React.useRef(null);
  var searchInputRef = React.useRef(null);
  var match = Hooks.useDisclosure(false);
  var actions = match[1];
  var opened = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchInput = match$1[1];
  var searchInput$1 = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setObtainedWidth = match$2[1];
  var obtainedWidth = match$2[0];
  var filteredValues = searchInput$1.trim() === "" ? items : Fuzzaldrin.filter(items, searchInput$1, {
          key: "label",
          maxResults: 15
        });
  var hasNoValues = filteredValues.length === 0;
  React.useEffect((function () {
          var timeout = setTimeout((function (param) {
                  if (!opened) {
                    return ;
                  }
                  var focusableElements = document.querySelectorAll("input, button, a, textarea, select, [tabindex]");
                  Belt_Array.forEach(focusableElements, (function (prim) {
                          prim.blur();
                          
                        }));
                  var el = searchInputRef.current;
                  if (!(el == null)) {
                    el.focus();
                    return ;
                  }
                  
                }), 50);
          return (function (param) {
                    clearTimeout(timeout);
                    
                  });
        }), [opened]);
  var match$3 = React.useState(function () {
        return false;
      });
  var setHasFocus = match$3[1];
  var hasFocus = match$3[0];
  React.useEffect((function () {
          var handleInput = function (e) {
            if (!(hasFocus && !opened)) {
              return ;
            }
            var key = e.key;
            if (Caml_option.null_to_opt(key.match(new RegExp("^\\w$"))) !== undefined) {
              Curry._1(actions.open_, undefined);
              return Curry._1(setSearchInput, (function (param) {
                            return key;
                          }));
            }
            
          };
          document.addEventListener("keydown", handleInput);
          return (function (param) {
                    document.removeEventListener("keydown", handleInput);
                    
                  });
        }), [
        hasFocus,
        opened
      ]);
  KeyListener.use(undefined, undefined, undefined, (function (e) {
          if (opened) {
            e.stopPropagation();
            e.preventDefault();
            return Curry._1(actions.close, undefined);
          } else if (hasFocus) {
            e.stopPropagation();
            e.preventDefault();
            Belt_Option.forEach(onEscape, (function (cb) {
                    return Curry._1(cb, undefined);
                  }));
            return ;
          } else {
            return ;
          }
        }), "Escape");
  KeyListener.use(undefined, undefined, undefined, (function (e) {
          if (hasFocus) {
            e.preventDefault();
            return Belt_Option.forEach(onEnter, (function (cb) {
                          return Curry._1(cb, undefined);
                        }));
          }
          
        }), "Enter");
  KeyListener.use(undefined, undefined, undefined, (function (param) {
          if (hasFocus && !opened) {
            return Curry._1(actions.open_, undefined);
          }
          
        }), "ArrowDown");
  KeyListener.use(undefined, undefined, undefined, (function (e) {
          if (opened) {
            e.stopPropagation();
            e.preventDefault();
            return ;
          }
          
        }), "Tab");
  var tmp;
  if (arbitraryChoice !== "off" && searchInput$1.trim() !== "" && Belt_Array.every(items, (function (item) {
            return item.label !== searchInput$1;
          }))) {
    var disabled = typeof arbitraryChoice === "object" ? arbitraryChoice.NAME !== "withMessage" : arbitraryChoice === "off";
    tmp = React.createElement("div", {
          className: Curry._1(Css.style, {
                hd: Css.focusWithin({
                      hd: Css.outline(Css.px(1), "solid", Styles.Color.red),
                      tl: /* [] */0
                    }),
                tl: /* [] */0
              })
        }, React.createElement(Mantine.Menu.Item.make, {
              closeMenuOnClick: true,
              additionalStyles: dropdownItemContainer,
              onClick: (function (param) {
                  Belt_Option.forEach(onChange, (function (cb) {
                          return Curry._1(cb, searchInput$1);
                        }));
                  return Curry._1(setSearchInput, (function (param) {
                                return "";
                              }));
                }),
              disabled: disabled,
              children: null,
              key: "add-arbitrary-choice"
            }, React.createElement("div", {
                  className: Curry._1(Css.merge, {
                        hd: dropdownItem,
                        tl: {
                          hd: Curry._1(dropdownItemStyles, false),
                          tl: {
                            hd: Curry._1(Css.style, {
                                  hd: Css.selector(" arbitrary-choice__label", {
                                        hd: Icon.styles({
                                              hd: Css.transition({
                                                    NAME: "ms",
                                                    VAL: Styles.Duration.$$short
                                                  }, undefined, undefined, "all"),
                                              tl: /* [] */0
                                            }),
                                        tl: /* [] */0
                                      }),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.selector(" arbitrary-choice__label", {
                                                hd: Icon.color(Styles.Color.deepBlue),
                                                tl: /* [] */0
                                              }),
                                          tl: /* [] */0
                                        }),
                                    tl: /* [] */0
                                  }
                                }),
                            tl: /* [] */0
                          }
                        }
                      })
                }, multiSelect ? React.createElement("input", {
                        checked: false,
                        readOnly: true,
                        type: "checkbox"
                      }) : null, React.createElement("div", {
                      className: Curry._1(Css.style, {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.alignItems("center"),
                              tl: {
                                hd: Css.gap(Css.px(8)),
                                tl: {
                                  hd: Css.color(disabled ? Styles.Color.light08 : Styles.Color.darkBlue),
                                  tl: {
                                    hd: Css.hover({
                                          hd: Css.color(Styles.Color.deepBlue),
                                          tl: {
                                            hd: Icon.color(Styles.Color.deepBlue),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }) + " arbitrary-choice__label"
                    }, React.createElement(Icon.make, {
                          type_: "plus",
                          size: "small",
                          color: disabled ? Styles.Color.light08 : Styles.Color.darkBlue
                        }), React.createElement($$Text.make, {
                          element: "Span",
                          size: "Small",
                          weight: "Semi",
                          children: "Add \"" + searchInput$1 + "\""
                        }))), typeof arbitraryChoice === "object" ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                        height: 8
                      }), React.createElement("div", {
                        className: Curry._1(Css.style, {
                              hd: obtainedWidth !== undefined ? Css.width(Css.px(obtainedWidth - 32 | 0)) : Styles.emptyStyle,
                              tl: {
                                hd: Css.maxWidth(Css.px(180)),
                                tl: {
                                  hd: Css.paddingLeft(Css.px(24)),
                                  tl: {
                                    hd: Css.paddingBottom(Css.px(8)),
                                    tl: /* [] */0
                                  }
                                }
                              }
                            })
                      }, React.createElement($$Text.make, {
                            size: "Small",
                            weight: "Regular",
                            color: Styles.Color.light10,
                            children: Curry._1(arbitraryChoice.VAL, searchInput$1)
                          }))) : null));
  } else {
    tmp = null;
  }
  return React.createElement(Mantine.Menu.make, {
              closeOnEscape: false,
              onClose: (function (param) {
                  Belt_Option.forEach(onClose, (function (cb) {
                          return Curry._1(cb, undefined);
                        }));
                  return Curry._1(actions.close, undefined);
                }),
              opened: opened,
              position: "bottom-start",
              returnFocus: true,
              zIndex: 1002,
              children: null
            }, React.createElement(Core.Menu.Target, {
                  children: React.createElement("div", {
                        className: targetRoot
                      }, React.createElement("div", {
                            className: Curry._1(Css.merge, {
                                  hd: targetContainer,
                                  tl: {
                                    hd: targetContainerStyles,
                                    tl: {
                                      hd: opened ? targetOpenedStyles : Curry._1(Css.style, /* [] */0),
                                      tl: /* [] */0
                                    }
                                  }
                                })
                          }, React.createElement("div", {
                                className: Curry._1(Css.merge, {
                                      hd: targetContent,
                                      tl: {
                                        hd: targetContentStyles,
                                        tl: /* [] */0
                                      }
                                    })
                              }, React.cloneElement(children, {
                                    onClick: (function (param) {
                                        Curry._1(actions.toggle, undefined);
                                        return Curry._1(setSearchInput, (function (param) {
                                                      return "";
                                                    }));
                                      }),
                                    onFocus: (function (param) {
                                        return Curry._1(setHasFocus, (function (param) {
                                                      return true;
                                                    }));
                                      }),
                                    onBlur: (function (param) {
                                        return Curry._1(setHasFocus, (function (param) {
                                                      return false;
                                                    }));
                                      })
                                  }))), rightSection !== undefined ? Caml_option.valFromOption(rightSection) : null)
                }), React.createElement(Mantine.Menu.Dropdown.make, {
                  additionalStyles: Belt_List.concat(dropdown, dropdownContainerStyles),
                  children: null
                }, errorMessage !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Core.Menu.Label, {
                            children: React.createElement("div", {
                                  className: Curry._1(Css.style, {
                                        hd: obtainedWidth !== undefined ? Css.maxWidth(Css.px(obtainedWidth - 32 | 0)) : Styles.emptyStyle,
                                        tl: /* [] */0
                                      })
                                }, React.createElement($$Text.make, {
                                      weight: "Regular",
                                      children: React.createElement("em", {
                                            className: Curry._1(Css.style, {
                                                  hd: Css.maxWidth(Css.px(200)),
                                                  tl: /* [] */0
                                                })
                                          }, errorMessage)
                                    }))
                          }), React.createElement(Mantine.Menu.Divider.make, {})) : null, items.length > maxItemsBeforeOfferingSearch ? React.createElement(React.Fragment, undefined, React.createElement(Mantine.Menu.Item.make, {
                            closeMenuOnClick: false,
                            children: React.createElement("div", {
                                  ref: searchContainerRef,
                                  className: searchContainer(obtainedWidth)
                                }, React.createElement("input", {
                                      ref: searchInputRef,
                                      className: searchInput,
                                      placeholder: "Search...",
                                      type: "text",
                                      value: searchInput$1,
                                      onFocus: (function (param) {
                                          var el = searchContainerRef.current;
                                          if (!(el == null) && searchInput$1 === "") {
                                            return Curry._1(setObtainedWidth, (function (param) {
                                                          return el.offsetWidth;
                                                        }));
                                          }
                                          
                                        }),
                                      onChange: (function (e) {
                                          var value = e.target.value;
                                          return Curry._1(setSearchInput, (function (param) {
                                                        return value;
                                                      }));
                                        })
                                    }), React.createElement("button", {
                                      className: eraseButton(searchInput$1 === ""),
                                      disabled: searchInput$1 === "",
                                      onClick: (function (param) {
                                          return Curry._1(setSearchInput, (function (param) {
                                                        return "";
                                                      }));
                                        })
                                    }, searchInputResetIcon)),
                            key: "search"
                          }), React.createElement(Mantine.Menu.Divider.make, {})) : null, hasNoValues ? React.createElement(Mantine.Menu.Item.make, {
                        closeMenuOnClick: false,
                        additionalStyles: dropdownItemContainer,
                        children: React.createElement("div", {
                              className: Curry._1(Css.merge, {
                                    hd: dropdownItem,
                                    tl: {
                                      hd: Curry._1(dropdownItemStyles, false),
                                      tl: /* [] */0
                                    }
                                  })
                            }, React.createElement($$Text.make, {
                                  size: "Small",
                                  weight: "Semi",
                                  children: "No results"
                                })),
                        key: "no-results"
                      }) : null, Belt_Array.map(filteredValues, (function (item) {
                        var match = item.valid;
                        var tmp = {
                          size: "Small",
                          children: null
                        };
                        var tmp$1 = match !== undefined && !match ? Styles.Color.darkOrange : undefined;
                        if (tmp$1 !== undefined) {
                          tmp.color = Caml_option.valFromOption(tmp$1);
                        }
                        var rightSection = item.rightSection;
                        var message = item.message;
                        return React.createElement(Mantine.Menu.Item.make, {
                                    closeMenuOnClick: !multiSelect,
                                    additionalStyles: Belt_List.concat(dropdownItemContainer, {
                                          hd: item.selected ? Css.backgroundColor(Styles.Color.light02) : Styles.emptyStyle,
                                          tl: /* [] */0
                                        }),
                                    onClick: (function (param) {
                                        if (!Belt_Option.getWithDefault(Caml_option.undefined_to_opt(item.valid), true)) {
                                          return ;
                                        }
                                        var value = item.value;
                                        Belt_Option.forEach(onChange, (function (cb) {
                                                return Curry._1(cb, value);
                                              }));
                                        return Curry._1(setSearchInput, (function (param) {
                                                      return "";
                                                    }));
                                      }),
                                    children: null,
                                    key: item.label
                                  }, React.createElement("div", {
                                        className: Curry._1(Css.merge, {
                                              hd: dropdownItem,
                                              tl: {
                                                hd: Curry._1(dropdownItemStyles, item.selected),
                                                tl: /* [] */0
                                              }
                                            })
                                      }, multiSelect ? React.createElement("input", {
                                              checked: item.selected,
                                              readOnly: true,
                                              type: "checkbox"
                                            }) : null, React.createElement($$Text.make, tmp, item.label, rightSection !== undefined ? rightSection : null)), message !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(Spacer.make, {
                                              height: 8
                                            }), React.createElement("div", {
                                              className: Curry._1(Css.style, {
                                                    hd: obtainedWidth !== undefined ? Css.width(Css.px(obtainedWidth - 32 | 0)) : Styles.emptyStyle,
                                                    tl: {
                                                      hd: Css.maxWidth(Css.px(180)),
                                                      tl: {
                                                        hd: Css.paddingBottom(Css.px(8)),
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  })
                                            }, React.createElement($$Text.make, {
                                                  size: "Small",
                                                  weight: "Regular",
                                                  color: Styles.Color.light10,
                                                  children: React.createElement("em", undefined, message)
                                                }))) : null);
                      })), tmp, confirmSelection ? React.createElement(Mantine.Menu.Item.make, {
                        additionalStyles: dropdownDoneContainer,
                        onClick: (function (param) {
                            Curry._1(actions.close, undefined);
                            return Curry._1(setSearchInput, (function (param) {
                                          return "";
                                        }));
                          }),
                        children: React.createElement("div", {
                              className: dropdownDoneButton
                            }, React.createElement(Icon.make, {
                                  type_: "simpleCheckmark",
                                  size: "small"
                                }), React.createElement($$Text.make, {
                                  size: "Medium",
                                  weight: "Semi",
                                  children: "Done"
                                }))
                      }) : null));
}

var make = SearchSelect;

export {
  Style ,
  Item ,
  make ,
  
}
/* targetRoot Not a pure module */
