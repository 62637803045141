// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Models from "./Models.mjs";
import * as Firebase from "../../bs-firestore/src/Firebase.mjs";
import * as DateFns from "date-fns";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ActionUtils from "../../model/src/ActionUtils.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as FirebaseFetcherHooks from "./FirebaseFetcherHooks.mjs";
import * as BranchStateFirebaseModel from "../../shared/models/BranchStateFirebaseModel.mjs";

function FirebaseFetchers$MergeBranchIds(Props) {
  var children = Props.children;
  var fetchToDate = Props.fetchToDate;
  var schemaId = Props.schemaId;
  return Curry._1(children, FirebaseFetcherHooks.useMergedBranchIds(fetchToDate, schemaId));
}

var MergeBranchIds = {
  make: FirebaseFetchers$MergeBranchIds
};

function FirebaseFetchers$BranchOpenAction(Props) {
  var schemaId = Props.schemaId;
  var branchId = Props.branchId;
  var includeActions = Props.includeActions;
  var children = Props.children;
  return Curry._1(children, FirebaseFetcherHooks.useBranchOpenAction(schemaId, branchId, includeActions, undefined, undefined));
}

var BranchOpenAction = {
  make: FirebaseFetchers$BranchOpenAction
};

var PagedStreamHook = FirebaseFetcherHooks.MakePagedStreamHook({
      getId: Models.Action.getId,
      getCreatedAt: Models.Action.getCreatedAt
    });

function useStreamResult(goToId, initialPageSize, pageSize, queryBy, queryByOpOpt, fetchToDate, fetchFromDate, fetchToAndEqualDate, branchId, disabled, audit, actionImpactLevel, collection) {
  var queryByOp = queryByOpOpt !== undefined ? queryByOpOpt : "==";
  return Curry._8(PagedStreamHook.usePagedStream, pageSize, initialPageSize, goToId, (function (query) {
                var query$1 = query.orderBy("createdAt", "desc");
                var query$2 = query$1.orderBy("order", "desc");
                var query$3 = fetchToDate !== undefined ? query$2.where("createdAt", "<", Caml_option.valFromOption(fetchToDate)) : query$2;
                var query$4 = fetchToAndEqualDate !== undefined ? query$3.where("createdAt", "<=", Caml_option.valFromOption(fetchToAndEqualDate)) : query$3;
                var query$5 = fetchFromDate !== undefined ? query$4.where("createdAt", ">", Caml_option.valFromOption(fetchFromDate)) : query$4;
                var query$6 = queryBy !== undefined && Belt_List.length(queryBy) > 0 ? Belt_List.reduce(queryBy, query$5, (function (query, param) {
                          return query.where(param[0], queryByOp, param[1]);
                        })) : query$5;
                var query$7 = branchId === "master" ? query$6 : query$6.where("branchId", "==", branchId);
                var query$8 = audit !== undefined ? query$7.where("audit", "==", audit) : query$7;
                if (actionImpactLevel === "ActivityLogs") {
                  return query$8.where("actionImpactLevel", "in", [
                              ActionUtils.ActionImpactLevel.tToJs("ActivityLogs"),
                              ActionUtils.ActionImpactLevel.tToJs("TrackingPlanModelChange")
                            ]);
                } else if (actionImpactLevel === "TrackingPlanModelChange") {
                  return query$8.where("actionImpactLevel", "==", ActionUtils.ActionImpactLevel.tToJs("TrackingPlanModelChange"));
                } else {
                  return query$8;
                }
              }), FirebaseFetcherHooks.transformAction, undefined, disabled, collection);
}

function FirebaseFetchers$PagedActionsStream(Props) {
  var schemaId = Props.schemaId;
  var queryBy = Props.queryBy;
  var goToId = Props.goToId;
  var pageSizeOpt = Props.pageSize;
  var initialPageSizeOpt = Props.initialPageSize;
  var fetchFromDate = Props.fetchFromDate;
  var fetchToDate = Props.fetchToDate;
  var fetchToAndEqualDate = Props.fetchToAndEqualDate;
  var branchIdOpt = Props.branchId;
  var audit = Props.audit;
  var actionImpactLevel = Props.actionImpactLevel;
  var children = Props.children;
  var pageSize = pageSizeOpt !== undefined ? pageSizeOpt : 100;
  var initialPageSize = initialPageSizeOpt !== undefined ? initialPageSizeOpt : 100;
  var branchId = branchIdOpt !== undefined ? branchIdOpt : "master";
  var collection = Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions");
  var streamResult = useStreamResult(goToId, initialPageSize, pageSize, queryBy, undefined, fetchToDate, fetchFromDate, fetchToAndEqualDate, branchId, false, audit, actionImpactLevel, collection);
  var importQueryBy = Belt_Option.map(queryBy, (function (queryBy) {
          return Belt_List.keepMap(queryBy, (function (param) {
                        var id = param[1];
                        var maybeNewKey;
                        switch (param[0]) {
                          case "context.eventQuery" :
                              maybeNewKey = "context.eventsQuery";
                              break;
                          case "context.eventVariantQuery" :
                              maybeNewKey = "context.eventVariantsQuery";
                              break;
                          case "context.propertyGroupQuery" :
                              maybeNewKey = "context.propertyGroupsQuery";
                              break;
                          case "context.propertyQuery" :
                              maybeNewKey = "context.propertiesQuery";
                              break;
                          case "context.sourceQuery" :
                              maybeNewKey = "context.sourcesQuery";
                              break;
                          default:
                            maybeNewKey = undefined;
                        }
                        return Belt_Option.map(maybeNewKey, (function (newKey) {
                                      return [
                                              newKey,
                                              id
                                            ];
                                    }));
                      }));
        }));
  var importQueryBy$1 = importQueryBy !== undefined && importQueryBy ? importQueryBy : undefined;
  var importResult = useStreamResult(goToId, initialPageSize, pageSize, importQueryBy$1, "array-contains", fetchToDate, fetchFromDate, fetchToAndEqualDate, branchId, importQueryBy$1 === undefined, audit, actionImpactLevel, collection);
  return Curry._4(children, Belt_List.sort(Belt_List.concat(importResult.items, streamResult.items), (function (a, b) {
                    return DateFns.compareAsc(Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(a.createdAt), new Date(), (function (prim) {
                                      return prim.toDate();
                                    })), Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(b.createdAt), new Date(), (function (prim) {
                                      return prim.toDate();
                                    })));
                  })), streamResult.status, streamResult.lastPageStartPosition, streamResult.getMore);
}

var PagedActionsStream = {
  PagedStreamHook: PagedStreamHook,
  useStreamResult: useStreamResult,
  make: FirebaseFetchers$PagedActionsStream
};

function fetchDeletedCommentActionsOnBranch(schemaId, branchId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).where("contentsJson.DeleteComment", ">", {}).get();
}

function fetchObjectCommentActionsOnBranch(schemaId, branchId, itemType) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("actions").where("branchId", "==", branchId).where(itemType === "Integration" ? "contentsJson.IntegrationComment" : (
                  itemType === "Property" ? "contentsJson.PropertyComment" : (
                      itemType === "Event" ? "contentsJson.EventComment" : (
                          itemType === "PropertyGroup" ? "contentsJson.PropertyGroupComment" : (
                              itemType === "Goal" ? "contentsJson.GoalComment" : (
                                  itemType === "InspectorIssue" ? "contentsJson.InspectorIssueComment" : (
                                      itemType === "Metric" ? "contentsJson.MetricComment" : "contentsJson.BranchComment"
                                    )
                                )
                            )
                        )
                    )
                ), ">", {}).get();
}

function fetchAllCommentActionsOnBranch(schemaId, branchId) {
  var allCommentActions = [
    "Event",
    "PropertyGroup",
    "Branch",
    "Metric",
    "Goal",
    "Property",
    "Integration",
    "InspectorIssue"
  ];
  return Promise.all(Belt_Array.mapU(allCommentActions, (function (commentAction) {
                    return fetchObjectCommentActionsOnBranch(schemaId, branchId, commentAction);
                  })));
}

function fetchBranchStatesWithGlobalId(schemaId, globalBranchId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).collection("branchStates").where("globalBranchId", "==", globalBranchId).get().then(function (querySnapshot) {
              return Belt_Array.get(Belt_Array.map(querySnapshot.docs, (function (doc) {
                                return BranchStateFirebaseModel.parseFirebaseType(doc.data());
                              })), 0);
            });
}

function getSchema(schemaId) {
  return Firebase.app(undefined).firestore().collection("schemas").doc(schemaId).get().then(function (snapshot) {
              return snapshot.data();
            });
}

function getSchemas(schemaIds) {
  var rootRef = Firebase.app(undefined).firestore().collection("schemas");
  var schemaIds$1 = Belt_SetString.toArray(Belt_SetString.fromArray(schemaIds));
  return Promise.all(Belt_Array.map(schemaIds$1, (function (schemaId) {
                      return rootRef.doc(schemaId).get();
                    }))).then(function (schemas) {
              return Belt_Array.map(schemas, (function (prim) {
                            return prim.data();
                          }));
            });
}

function fetchBranchStatesForSchemas(schemaIds, globalBranchId) {
  return Promise.all(Belt_Array.map(schemaIds, (function (schemaId) {
                      return fetchBranchStatesWithGlobalId(schemaId, globalBranchId).then(function (branchState) {
                                  if (branchState !== undefined) {
                                    return Promise.resolve({
                                                schemaId: schemaId,
                                                branchId: branchState.branchId,
                                                lastActivityDate: branchState.lastActivityDate,
                                                lastActiveUserId: branchState.lastActiveUserId,
                                                branchStatus: branchState.branchStatus,
                                                approvalsCount: branchState.approvalsCount,
                                                creatorUserId: branchState.creatorUserId,
                                                impactedSourceIds: branchState.impactedSourceIds,
                                                creationDate: branchState.creationDate,
                                                branchName: branchState.branchName,
                                                branchLongName: branchState.branchLongName,
                                                commentsCount: branchState.commentsCount,
                                                collaboratorIds: branchState.collaboratorIds,
                                                reviewerIds: branchState.reviewerIds,
                                                lastCodegenBySource: branchState.lastCodegenBySource,
                                                lastPublishedAt: branchState.lastPublishedAt,
                                                isShallow: branchState.isShallow,
                                                globalBranchId: branchState.globalBranchId
                                              });
                                  } else {
                                    return Promise.resolve(undefined);
                                  }
                                });
                    }))).then(function (items) {
              return [
                      globalBranchId,
                      Belt_Array.keepMap(items, (function (i) {
                              return i;
                            }))
                    ];
            });
}

export {
  MergeBranchIds ,
  BranchOpenAction ,
  PagedActionsStream ,
  fetchDeletedCommentActionsOnBranch ,
  fetchObjectCommentActionsOnBranch ,
  fetchAllCommentActionsOnBranch ,
  fetchBranchStatesWithGlobalId ,
  getSchema ,
  getSchemas ,
  fetchBranchStatesForSchemas ,
  
}
/* PagedStreamHook Not a pure module */
